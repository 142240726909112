import React, { PropsWithChildren } from "react";
import { ContentType } from "../../../../common/types/Data/ContentType";

interface IProps {
    type: ContentType;
    label: string;
}

export const GenericThumbnail = (props: PropsWithChildren<IProps>) => {
    const { type, label } = props;
    return (
        <div className={`thumbnail-generic thumbnail-generic-${type}`}>
            <span className={"label"}>{label}</span>
        </div>
    );
};
