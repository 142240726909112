import React, { PropsWithChildren } from "react";
import { LoadedContent } from "../../../../common/types/Data/Content";
import { File } from "../../../../common/types/Data/File";
import { GenericThumbnail } from "./GenericThumbnail";

interface IProps {
    entity: LoadedContent;
    thumbnail: undefined | File;
}

export const ConditionalGenericThumbnail = (props: PropsWithChildren<IProps>) => {
    const { entity, thumbnail } = props;

    return thumbnail ? <></> : <GenericThumbnail type={entity.type} label={entity.label} />;
};
