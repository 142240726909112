import { Action } from "redux";
import { SortOptions } from "../../../common/types/Data/SortOptions";
import { LoadedTagCollection } from "../../../common/types/Data/TagCollection";
import { ReduxActionType } from "../../../common/types/ReduxActionType";
import { actionReducer } from "../actionReducer";
import { initialState } from "../initialState";

export type SetCurrentTagCollectionAction = {
    type: ReduxActionType.SetCurrentTagCollection;
    currentTagCollection: LoadedTagCollection | undefined;
    currentSort: SortOptions | undefined;
};

export const createSetCurrentTagCollectionAction = (currentTagCollection: LoadedTagCollection | undefined, currentSort?: SortOptions): SetCurrentTagCollectionAction => {
    return {
        type: ReduxActionType.SetCurrentTagCollection,
        currentTagCollection,
        currentSort,
    };
};

export const isSetCurrentTagCollectionAction = (action: Action): action is SetCurrentTagCollectionAction => {
    return action.type === ReduxActionType.SetCurrentTagCollection;
};

export const setCurrentTagCollectionReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        const { currentTagCollection, currentSort } = action;
        const { currentTagCollection: previousTagCollection, currentSort: previousSort } = previousState;

        return {
            ...previousState,
            previousTagCollection,
            currentTagCollection,
            currentSort: currentSort === undefined ? previousSort : currentSort,
        };
    },
    isSetCurrentTagCollectionAction,
);
