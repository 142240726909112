import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { PagePost } from "../../../../common/Facebook/PagePost";
import { FacebookImageBaseModule } from "./FacebookImageBaseModule";
import { FacebookImagePopupModule } from "./FacebookImagePopupModule";

export const moduleLoader: ComponentModuleLoader<PagePost> = {
    baseModule: FacebookImageBaseModule,
    popupModule: FacebookImagePopupModule,
    payloadExtractor: (entity) => {
        return {
            entity,
        };
    },
    getSocialTags: (entity) => {
        return {
            description: entity.linkedContent.message,
            name: entity.linkedContent.message,
            previewImage: undefined,
        };
    },
};
