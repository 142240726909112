import React, { PropsWithChildren } from "react";
import { LoadedTagCollection } from "../../../common/types/Data/TagCollection";
import { withState, WithState } from "../../hocs/withState";
import { Banner } from "./Banner";

type Props = unknown;

const BannerParentDecorated = (props: PropsWithChildren<Props & WithState>) => {
    const {
        state: { currentTagCollection, previousTagCollection },
    } = props;

    const getBannerForTag = (tagCollection: LoadedTagCollection | undefined, active: boolean) => {
        const tagCollectionCode = tagCollection ? tagCollection.code : undefined;
        switch (tagCollectionCode) {
            case "play":
                return (
                    <Banner isActive={active} id={"kindergoBanner"} activeTagCollection={currentTagCollection}>
                        <div className="storeButtons">
                            <a className={"kindergoIOS"} href={"https://apps.apple.com/au/app/kindergo/id1254229153"} target="_blank" rel="noopener noreferrer" />
                            <a className={"kindergoAndroid"} href={"https://play.google.com/store/apps/details?id=com.likeaphoton.kindergo"} target="_blank" rel="noopener noreferrer" />
                        </div>
                    </Banner>
                );
            case "download":
            case "read":
            case undefined:
            default:
                return (
                    <Banner isActive={active} id={"fairyWorldQuestBanner"} activeTagCollection={currentTagCollection}>
                        <div className="storeButtons">
                            <a className={"gameIOS"} href={"https://apps.apple.com/us/app/sanctuary-world/id1493900591"} target="_blank" rel="noopener noreferrer" />
                            <a className={"gameAndroid"} href={"https://play.google.com/store/apps/details?id=com.likeaphoton.tfscmatch"} target="_blank" rel="noopener noreferrer" />
                        </div>
                    </Banner>
                );
        }
    };

    const createInactiveBanner = () => {
        if (previousTagCollection != currentTagCollection) {
            return getBannerForTag(previousTagCollection, false);
        }
        return;
    };

    const createActiveBanner = () => {
        return getBannerForTag(currentTagCollection, true);
    };

    return (
        <>
            <div className="bannerParent">
                {createInactiveBanner()}
                {createActiveBanner()}
            </div>
        </>
    );
};

export const BannerParent = withState(BannerParentDecorated);
