import { CSSProperties } from "react";
import { buildGridTemplateAreas } from "./buildGridTemplateAreas";
import { expandGridStructure } from "./expandGridStructure";
import { GridStructure } from "./GridStructure";
import { GridProperties } from "./GridProperties";

/**
 * Builds the CSS properties for the passed grid.
 *
 * @param {GridStructure} gridStructure
 * @param {GridProperties} gridProperties
 * @param {boolean} useHorizontal
 *
 * @returns {CSSProperties}
 */
export const buildGridStyle = (gridStructure: GridStructure, gridProperties: GridProperties, useHorizontal: boolean, isDesktop: boolean): CSSProperties => {
    const { pagesRequired, columnsPerPage, rowsPerPage } = gridProperties;

    // Expand the grid structure to fill the required number of pages
    const expandedGridStructure = expandGridStructure(gridStructure, gridProperties, useHorizontal);

    // Turn the numeric array into a string, ready for CSS
    const gridTemplateAreas = buildGridTemplateAreas(expandedGridStructure);

    const baseHeightValue = isDesktop && window != undefined ? window.innerWidth / 21 : 40;

    const columnGaps = columnsPerPage - 1;
    const columnGapWidth = 2;
    const rowGapWidth = 2;
    const rowGaps = rowsPerPage - 1;
    const columnWidth = (1 / columnsPerPage) * (100 - columnGaps * columnGapWidth);
    const rowHeight = (1 / rowsPerPage) * (baseHeightValue - rowGaps * rowGapWidth);

    //Calculate the height of the page.
    const totalHeight = rowHeight * gridProperties.lowestRow + rowGaps * gridProperties.lowestRow;

    const gridTemplateColumns = useHorizontal ? `${columnWidth}% `.repeat(columnsPerPage * pagesRequired) : `${columnWidth}% `.repeat(columnsPerPage);
    const gridTemplateRows = useHorizontal ? `${rowHeight}% `.repeat(rowsPerPage) : `${rowHeight}vh `.repeat(rowsPerPage);

    if (useHorizontal) {
        return {
            gridTemplateColumns,
            gridTemplateRows,
            gridTemplateAreas,
            gridRowGap: `${rowGapWidth}%`,
            gridColumnGap: `${columnGapWidth}%`,
        };
    } else {
        return {
            gridTemplateColumns,
            gridTemplateRows,
            gridTemplateAreas,
            gridRowGap: `${rowGapWidth}vh`,
            gridColumnGap: `${columnGapWidth}%`,
            height: `${totalHeight}vh`,
        };
    }
};
