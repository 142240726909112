import React, { PropsWithChildren } from "react";

interface IProps {
    show: boolean;
    imageName?: string;
}

export const Loader = (props: PropsWithChildren<IProps>) => {
    const { show } = props;
    let { imageName } = props;

    if (imageName === undefined) {
        imageName = "tfsc-preloader";
    }

    const display = show ? "flex" : "none";

    const style: React.CSSProperties = {
        display,
    };

    return (
        <div className={`preloader ${imageName}`} style={style}>
            <div className={"loaderArt"} />
        </div>
    );
};
