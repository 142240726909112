import React, { ReactElement } from "react";
import { LoadedContent } from "../../common/types/Data/Content";
import { RenderComponentConfig } from "../../common/types/Data/RenderComponentConfig";
import { LinkedContent } from "../../common/types/Data/LinkedContent";
import { BadModule } from "../components/Modules/BadModule";
import { BaseComponentProps } from "../../common/types/Data/BaseComponentProps";
import { getModuleLoader } from "./getModuleLoader";

/**
 * Renders the loaded Component from the API data
 *
 * @template {TComponentProps} The properties that the created and returned component will have.
 * @template {TLinkedContent} The type of linked content
 *
 * @param {Content & WithLinkedContent<TLinkedContent>} entity
 * @param {RenderComponentConfig} config
 *
 * @returns {ReactElement<TComponentProps>}
 */
export const renderComponent = <TLinkedContent extends LinkedContent, TComponentProps extends BaseComponentProps<TLinkedContent>>(entity: LoadedContent<TLinkedContent>, config: RenderComponentConfig): ReactElement<TComponentProps> => {
    try {
        const { useDetailModule, nested } = config;
        const type = entity.type;

        // Record the type of content
        const moduleAndExtractor = getModuleLoader<TLinkedContent, TComponentProps>(type);

        if (moduleAndExtractor) {
            const { baseModule, popupModule, payloadExtractor } = moduleAndExtractor;

            const props = { ...payloadExtractor(entity, config), nested } as TComponentProps;

            if (useDetailModule) {
                return React.createElement(popupModule, props);
            } else {
                return React.createElement(baseModule, props);
            }
        } else {
            throw new TypeError(`No Component for type ${type} was found.`);
        }
    } catch (error) {
        return <BadModule error={error} entity={entity} />;
    }
};
