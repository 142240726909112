import React, { PropsWithChildren } from "react";

type Props = {
    id: string;
    overlayId?: string;
};

export const Leaf = (props: PropsWithChildren<Props>) => {
    const { id, overlayId } = props;

    return (
        <div className="leafOverlay" id={overlayId}>
            <div className="leafArt" id={id} />
        </div>
    );
};
