import React, { PropsWithChildren } from "react";

type Props = {
    className?: string;
    label: string;
};

export const ModulePreviewHeader = (props: PropsWithChildren<Props>) => {
    const { className, label, children } = props;
    return (
        <div className={[`module-preview-header`, className ? className : ""].join(" ")}>
            <h4>{label}</h4>
            {children}
        </div>
    );
};
