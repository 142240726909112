import { PopulatedContent } from "../../../common/types/Data/Content";
import { StaticModuleLinkedContent } from "../../data/static-module-linked-content";
import { ModuleFetcher } from "./ModuleFetcher";

export const moduleStaticFetcherFactory = (): ModuleFetcher => {
    const entities: PopulatedContent[] = StaticModuleLinkedContent.entities as never;

    return async (): Promise<PopulatedContent[]> => {
        return entities;
    };
};
