import React, { PropsWithChildren } from "react";
import { LoadedContent } from "../../../../common/types/Data/Content";
import { PopulatedLocationProfile } from "../../../../common/types/Data/PopulatedLocationProfile";
import { PopupImage } from "../Common/PopupImage";

type Props = {
    entity: LoadedContent<PopulatedLocationProfile>;
};

export const StaticLocationProfileCore = (props: PropsWithChildren<Props>) => {
    const { entity } = props;

    const description: string[] = entity.linkedContent.description.split("\n");

    return (
        <div className={"profileBase"}>
            <PopupImage entity={entity} />
            <div className={"locationDescription"}>
                {description.map((line, index) => {
                    return (
                        <div key={`line-${index}`}>
                            {line}
                            {index < description.length - 1 ? <br /> : undefined}
                        </div>
                    );
                })}{" "}
            </div>
        </div>
    );
};
