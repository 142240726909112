import React, { PropsWithChildren } from "react";

type Props = {
    id: string;
};

export const Dropbear = (props: PropsWithChildren<Props>) => {
    const { id } = props;
    return <div className="dropBear" id={id} />;
};
