import React, { PropsWithChildren, useState } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { InteractiveMap as InteractiveMapData } from "../../../../common/types/Data/InteractiveMap";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { ResponsiveStateListener } from "../../Utilities/ResponsiveStateListener";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ModulePreviewImage } from "../Common/ModulePreviewImage";
import { InteractiveMap } from "./InteractiveMap";

export type Props = BaseComponentProps<InteractiveMapData>;

const StaticMapModuleDecorated = (props: PropsWithChildren<Props & WithContentNavigator>) => {
    const { entity, contentNavigator } = props;
    const [isDesktop, setIsDesktop] = useState(false);
    const [forceUnmount, setForceUnmount] = useState(false);

    const onUnmount = () => {
        setForceUnmount(false);
    };

    const onClick = () => {
        setForceUnmount(true);
        contentNavigator.open("interactiveMap", { entity, mountEventName: "leafletmap.mount", unmountEventName: "leafletmap.unmount", unmountCallback: onUnmount });
    };

    return (
        <>
            <ResponsiveStateListener
                queries={{
                    isDesktopOrLaptop: "(min-device-width: 1024px)",
                }}
                onChange={(newResponsiveState) => {
                    setIsDesktop(newResponsiveState.isDesktopOrLaptop);
                }}
            />
            {isDesktop && !forceUnmount ? (
                <>
                    <div className={"expandButton"} onClick={onClick} />
                    <InteractiveMap entity={entity} />
                </>
            ) : (
                <ModulePreview classNames={["download"]} expandable={true} onClick={onClick}>
                    <ModulePreviewHeader label={"Explore Sanctuary City"} />
                    <ModulePreviewImage entity={entity} file={undefined} noConditional={true} />
                </ModulePreview>
            )}
        </>
    );
};

export const StaticMapModule = withContentNavigator(StaticMapModuleDecorated);
