import { genericShareDescription } from "../../common/consts/genericShareDescription";
import { LoadedContent } from "../../common/types/Data/Content";
import { WithThumbnailFile } from "../../common/types/Data/File";
import { LinkedContent } from "../../common/types/Data/LinkedContent";
import { SocialTags } from "../../common/types/Data/SocialTags";
import { buildFileOptionalCdnUri } from "./buildOptionalFileCdnUri";

type SocialLinkedContent = WithThumbnailFile & {
    label: string;
};

export const generateGenericSocialTags = (entity: LoadedContent<LinkedContent & SocialLinkedContent>): SocialTags => {
    return {
        previewImage: buildFileOptionalCdnUri(entity.linkedContent.thumbnailFile),
        description: genericShareDescription,
        name: entity.label,
    };
};
