import React, { PropsWithChildren } from "react";
import { PagePost } from "../../../../common/Facebook/PagePost";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { withActions, WithActions } from "../../../hocs/withActions";
import { ViewOnFacebook } from "../FacebookCommon/ViewOnFacebook";

export type Props = BaseComponentProps<PagePost> & {
    size?: string;
};

const FacebookImagePopupModuleDecorated = (props: PropsWithChildren<Props & WithActions>) => {
    const { entity, size } = props;

    const style = {
        backgroundImage: `url('${entity.linkedContent.attachments.data[0].media.image.src}')`,
        backgroundSize: size || "contain",
    };

    return (
        <div className={"facebookPopupBase"}>
            <div style={style} className={"popupimage noselect"}>
                <ViewOnFacebook post={entity.linkedContent} />
            </div>
            <div className={"facebookText"}>{entity.label}</div>
        </div>
    );
};

export const FacebookImagePopupModule = withActions(FacebookImagePopupModuleDecorated);
