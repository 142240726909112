import React, { PropsWithChildren } from "react";
import { ContentType } from "../../../../common/types/Data/ContentType";

interface IProps {
    type: ContentType;
    engagement: number;
    watched: string | undefined;
    id?: number;
}

//@Todo: Put this somewhere better.
// Returns image based on the provided content type
export const findIconTagForContentType = (type: string): string => {
    // Default type
    let imageName;

    // Choose icon based on type
    switch (type) {
        case "interactiveMap":
            imageName = "map-icon";
            break;

        case "locProfile":
            imageName = "location-icon";
            break;

        case "video":
        case "youtube:video":
        case "facebook:video":
            imageName = "watchicon";
            break;

        case "downloadable":
            imageName = "downloadicon";
            break;

        case "charProfile":
            imageName = "profileicon";
            break;

        case "quiz":
            imageName = "quizicon";
            break;

        case "imageGallery":
        case "facebook:gallery":
            imageName = "galleryicon";
            break;

        case "facebook:link":
            imageName = "linkicon";
            break;

        case "newsFeed":
        case "externalContent":
        case "image":
        case "text":
        case "facebook:image":
        default:
            imageName = "exploreicon";
            break;
    }

    return imageName;
};

const getImageClassName = (id: number | undefined, type: ContentType): string => {
    // @todo WTF?
    if (id === 30) {
        return "linkicon";
    }

    if (type) {
        return findIconTagForContentType(type);
    }

    return "";
};

export const ComponentPanel = (props: PropsWithChildren<IProps>) => {
    const { type, engagement, watched, id, children } = props;

    //Find Image index based on name
    //Todo: Remove this terrible ID hack.
    const imageClassName = getImageClassName(id, type);
    let tooltipText = imageClassName.slice(0, Math.max(0, imageClassName.length - 4));
    tooltipText = tooltipText.replace(tooltipText.slice(0, 1), tooltipText.slice(0, 1).toUpperCase());

    const hasWatched = watched != undefined && watched == "true";
    const iconStyle = type.startsWith("facebook") ? "likesIcon" : "viewsIcon";

    return (
        <div className={"componentpanel"}>
            <div className={`panelicon ${imageClassName} tooltip`}>
                <span className={"tooltipText"}>{tooltipText}</span>
            </div>
            {engagement > 0 ? (
                <div className={"trending"}>
                    <div className={`trending-icon ${iconStyle}`} />
                    <div className={"trending-amount"}>{engagement}</div>
                </div>
            ) : undefined}

            {hasWatched ? (
                <div className={"watchedcontainer"}>
                    <div className={"watched"} />
                </div>
            ) : (
                ""
            )}
            {children}
        </div>
    );
};
