import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { PopulatedImageGallery } from "../../../../common/types/Data/PopulatedImageGallery";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ModulePreviewImage } from "../Common/ModulePreviewImage";

export type Props = BaseComponentProps<PopulatedImageGallery> & {
    isLarge?: boolean;
};

const StaticImageGalleryBaseModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics & WithContentNavigator>) => {
    const {
        entity,
        contentNavigator,
        nested,
        isLarge,
        analyticsUtils: { trackClickModuleEvent },
    } = props;

    const onClick = async () => {
        await trackClickModuleEvent(entity);
        contentNavigator.open("imageGallery", { entity }, { header: entity.label, nested });
    };

    const file = entity.linkedContent.imageGalleryImages.length > 0 ? (isLarge ? entity.linkedContent.imageGalleryImages[0].image.file : entity.linkedContent.imageGalleryImages[0].image.thumbnailFile) : undefined;

    return (
        <ModulePreview classNames={["GalleryImage", "image-gallery"]} onClick={onClick}>
            <ModulePreviewHeader className={"image-gallery"} label={entity.label} />
            <ModulePreviewImage entity={entity} file={file} />
        </ModulePreview>
    );
};

export const StaticImageGalleryBaseModule = withAnalytics(withContentNavigator(StaticImageGalleryBaseModuleDecorated));
