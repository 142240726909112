import { LoadedContent } from "../../common/types/Data/Content";

export const selectComponentLoaderStyle = (big: boolean, isSubModule: boolean, entity: LoadedContent | undefined): string | undefined => {
    if (big && entity) {
        if (!isSubModule) {
            switch (entity.type) {
                case "video":
                case "downloadable":
                case "downloadableList":
                case "external_content":
                    return "tfsc-preloader-yellow";
                case "image":
                case "newsFeed":
                case "charProfile":
                case "text":
                    return "tfsc-preloader-red";
                case "leafletMap":
                case "locProfile":
                case "quiz":
                    return "tfsc-preloader-green";
            }
        } else {
            //All submodules should use this loader.
            return "tfsc-preloader-green";
        }
    }

    return;
};
