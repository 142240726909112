import { BigModulePattern } from "./BigModulePattern";
import { findMaxIndex } from "./findMaxIndex";
import { GridStructure } from "./GridStructure";

export const deriveBigModulePattern = (gridStructure: GridStructure): BigModulePattern => {
    const pattern: BigModulePattern = [];

    gridStructure.forEach((row) => {
        row.forEach((value) => {
            //If the value is negative, and not already in the list, add it to the list.
            if (
                value < 0 &&
                !pattern.find((number) => {
                    return number === Math.abs(value);
                })
            ) {
                pattern.push(Math.abs(value));
            }
        });
    });

    pattern.sort((a, b) => {
        return a > b ? 1 : -1;
    });

    const output: number[] = [];
    if (pattern.length > 0) {
        output.push(pattern[0]);
    }

    for (let i = 1; i < pattern.length; ++i) {
        output.push(pattern[i] - pattern[i - 1]);
    }

    const maxIndex = findMaxIndex(gridStructure);
    let filler = 0;

    if (pattern[pattern.length - 1] + 1 < maxIndex) {
        filler = maxIndex - pattern[pattern.length - 1];
    }

    output.push(filler);

    return output;
};
