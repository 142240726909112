import { State } from "../../common/types/State";

export const initialState: State = {
    counter: 0,
    currentTagCollection: undefined,
    previousTagCollection: undefined,
    currentSort: "createdAt",
    currentModule: undefined,
    currentModuleData: undefined,
    currentOrder: "DESC",
};
