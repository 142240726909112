import React, { PropsWithChildren } from "react";
import { WithThumbnailFile } from "../../../../common/types/Data/File";
import { QuizAnswer as QuizAnswerData } from "../../../../common/types/Data/QuizAnswer";
import { StandardImageBox } from "../Common/StandardImageBox";

type Props = {
    quizAnswer: QuizAnswerData & WithThumbnailFile;
    onAnswerSelected: () => void;
    highlight?: boolean;
};

export const QuizAnswer = (props: PropsWithChildren<Props>) => {
    const { quizAnswer, onAnswerSelected, highlight } = props;
    const { label, thumbnailFile } = quizAnswer;

    const classNames = ["quiz-answer", highlight ? "highlighted" : ""];

    return (
        <div className={classNames.join(" ")} onClick={onAnswerSelected}>
            {thumbnailFile ? <StandardImageBox file={thumbnailFile} classNames={["answer-image"]} backgroundSize={"contain"} /> : undefined}
            <div className={"answer-text noselect"}>
                <span>{label}</span>
            </div>
        </div>
    );
};
