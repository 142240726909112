import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { ErrorMessage } from "../../../../common/types/Data/ErrorMessage";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ConfusedKerry } from "./ConfusedKerry";

export type Props = BaseComponentProps<ErrorMessage>;

export const ErrorBaseModule = (props: PropsWithChildren<Props>) => {
    const { entity } = props;

    const onClick = () => {
        window.location.href = "/";
    };

    return (
        <ModulePreview classNames={["error-module"]} onClick={onClick}>
            <ConfusedKerry />;
            <ModulePreviewHeader className={"image"} label={entity.linkedContent.message} />
        </ModulePreview>
    );
};
