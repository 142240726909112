import React, { PropsWithChildren } from "react";

type Props = {};

export const Gradient = (props: PropsWithChildren<Props>) => {
    const { children } = props;
    return (
        <div className="gradientHolder">
            <div className="gradient" />
            {children}
        </div>
    );
};
