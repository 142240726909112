import React, { CSSProperties, PropsWithChildren } from "react";
import { PagePost } from "../../../../common/Facebook/PagePost";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { WithContentNavigator, withContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withActions, WithActions } from "../../../hocs/withActions";
import { WithAnalytics, withAnalytics } from "../../../hocs/withAnalytics";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ModulePreviewImage } from "../Common/ModulePreviewImage";

export type Props = BaseComponentProps<PagePost> & {};

const buildBackgroundStyle = (pagePost: PagePost): CSSProperties => {
    return {
        backgroundImage: `url(${pagePost.full_picture})`,
        backgroundSize: "cover",
    };
};

const FacebookVideoBaseModuleDecorated = (props: PropsWithChildren<Props & WithActions & WithAnalytics & WithContentNavigator>) => {
    const {
        entity,
        contentNavigator,
        analyticsUtils: { trackClickModuleEvent },
        nested,
    } = props;

    const onClick = () => {
        trackClickModuleEvent(entity);
        contentNavigator.open("facebook:video", { entity }, { nested });
    };

    const style = buildBackgroundStyle(entity.linkedContent);

    return (
        <ModulePreview classNames={["VideoModule", "GalleryImage"]} onClick={onClick}>
            <ModulePreviewHeader className={"video"} label={entity.shortLabel || entity.label} />
            <ModulePreviewImage entity={entity} file={undefined} style={style} noConditional={true} />
        </ModulePreview>
    );
};

export const FacebookVideoBaseModule = withContentNavigator(withAnalytics(withActions(FacebookVideoBaseModuleDecorated)));
