import React, { ComponentType, PropsWithChildren } from "react";
import { Content, LoadedContent } from "../../common/types/Data/Content";
import { ContentType } from "../../common/types/Data/ContentType";
import { LinkedContent } from "../../common/types/Data/LinkedContent";
import { StaticModuleLinkedContent } from "../data/static-module-linked-content";
import { content404Error } from "./Modules/content404Error";
import { WithAPI, withAPI } from "./withAPI";
import { withModuleFetcher, WithModuleFetcher } from "./withModuleFetcher";

export type SingleModuleDataFetcher = <TLinkedContent extends LinkedContent>(moduleId: number | string) => Promise<LoadedContent<TLinkedContent>>;
export type ModuleDataFetcherByType = <TLinkedContent extends LinkedContent>(kind: ContentType) => Promise<LoadedContent<TLinkedContent>[]>;

export type WithModuleDataFetcher = {
    moduleDataFetcher: SingleModuleDataFetcher;
    moduleDataFetcherByType: ModuleDataFetcherByType;
};

const withModuleDataFetcherFactory = () => {
    const entities: Content[] = StaticModuleLinkedContent.entities as never;
    const errors: Content[] = [content404Error];
    return <TProps,>(Component: ComponentType<TProps & WithModuleDataFetcher>): ComponentType<TProps> => {
        const ComponentWithModuleDataFetcher = (props: PropsWithChildren<TProps & WithAPI & WithModuleFetcher>) => {
            const { moduleFetcher } = props;

            const moduleDataFetcher = async <TLinkedContent extends LinkedContent>(moduleId: number | string): Promise<LoadedContent<TLinkedContent>> => {
                // Errors
                let result = errors.find((candidate) => {
                    return `${candidate.id}` === moduleId;
                });

                if (!result) {
                    // Check local entities
                    result = entities.find((candidate) => {
                        return `${candidate.id}` === moduleId;
                    });
                }

                // Check remote entities
                if (!result) {
                    const content = await moduleFetcher(undefined, "createdAt", "DESC");
                    result = content.find((candidate) => {
                        return `${candidate.id}` === moduleId;
                    });
                }

                if (result) {
                    return result as LoadedContent<TLinkedContent>;
                } else {
                    throw new Error(`Failed to get content for module ID "${moduleId}".`);
                }
            };

            const moduleDataFetcherByType = async <TLinkedContent extends LinkedContent>(type: ContentType): Promise<LoadedContent<TLinkedContent>[]> => {
                return entities.filter((candidate) => {
                    return candidate.type === type;
                }) as LoadedContent<TLinkedContent>[];
            };

            return <Component {...props} moduleDataFetcher={moduleDataFetcher} moduleDataFetcherByType={moduleDataFetcherByType} />;
        };

        return withModuleFetcher(withAPI(ComponentWithModuleDataFetcher));
    };
};

export const withModuleDataFetcher = withModuleDataFetcherFactory();
