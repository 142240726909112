import React, { PropsWithChildren } from "react";
import { WithTag } from "../../../common/types/Data/Tag";

interface IProps {
    tags: WithTag[];
}

export const ModuleOverlay = (props: PropsWithChildren<IProps>) => {
    const { tags } = props;

    const createClassString = (): string => {
        return tags
            .map((entry) => {
                const tag = entry.tag.tag_id.replace(" ", "");
                return `tag-${tag}`;
            })
            .join(" ");
    };

    const classesString = createClassString();

    return (
        <>
            <div className={`moduleOverlay left ${classesString}`} />
            <div className={`moduleOverlay right ${classesString}`} />
        </>
    );
};
