import React, { PropsWithChildren, useState } from "react";
import { WithThumbnailFile } from "../../../../common/types/Data/File";
import { PopulatedQuiz } from "../../../../common/types/Data/PopulatedQuiz";
import { PopulatedQuizQuestion } from "../../../../common/types/Data/PopulatedQuizQuestion";
import { PopupComponentProps } from "../../../../common/types/Data/PopupComponentProps";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { QuizResult } from "./QuizResult";
import { QuizQuestion } from "./QuizQuestion";
import { selectQuizResult } from "./selectQuizResult";
import { QuizResult as QuizResultData } from "../../../../common/types/Data/QuizResult";
import { QuizAnswer as QuizAnswerData } from "../../../../common/types/Data/QuizAnswer";

type Props = PopupComponentProps<PopulatedQuiz> & {
    // By default, we'll show the result in a new popup (allowing a new header, showing related content, etc.)
    // If this value is true we'll just show the result in the existing popup
    displayResultInline?: boolean;
};

const StaticQuizPopupModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics & WithContentNavigator>) => {
    const {
        entity,
        displayResultInline,
        analyticsUtils: { trackContentEvent },
        contentNavigator,
    } = props;
    const { linkedContent } = entity;

    // Only one question, please!
    //linkedContent.quizQuestions = linkedContent.quizQuestions.slice(0, 1);

    const [result, setResult] = useState<(QuizResultData & WithThumbnailFile) | undefined>();
    const [currentQuestion, setCurrentQuestion] = useState<PopulatedQuizQuestion>(linkedContent.quizQuestions[0]);
    const [selectedAnswers, setSelectedAnswers] = useState<QuizAnswerData[]>([]);

    const onAnswerSelected = (quizQuestion: PopulatedQuizQuestion, quizAnswer: QuizAnswerData) => {
        trackContentEvent({ action: "answerSelected", label: `${entity.label}/${quizQuestion.label}/${quizAnswer.label}`, value: quizAnswer.id }, entity);
        const newSelectedAnswers = [...selectedAnswers, quizAnswer];
        setSelectedAnswers(newSelectedAnswers);
        const index = entity.linkedContent.quizQuestions.indexOf(quizQuestion);
        if (index < entity.linkedContent.quizQuestions.length - 1) {
            setCurrentQuestion(entity.linkedContent.quizQuestions[index + 1]);
        } else {
            const newResult = selectQuizResult(linkedContent.quizType, entity.linkedContent.quizResults, newSelectedAnswers);
            trackContentEvent({ action: "resultGotten", label: `${entity.label}/${newResult.label}`, value: newResult.id }, entity);
            if (displayResultInline) {
                setResult(newResult);
            } else {
                contentNavigator.openWith(
                    QuizResult,
                    {
                        entity,
                        quizResult: newResult,
                    },
                    {
                        header: `${entity.linkedContent.label} - Result`,
                        relatedContentId: `${entity.id}`,
                        showRelatedContent: true,
                        nested: true,
                    },
                );
            }
        }
    };

    // If still asking questions
    if (result === undefined) {
        return (
            <div className={["QuizColumn", `quiz-type-${entity.linkedContent.quizType}`].join(" ")}>
                <div className={"Details"}>
                    <div className={"Title noselect"}>{currentQuestion.label}</div>
                </div>
                <div className={"QuizList"}>
                    {entity.linkedContent.quizQuestions.map((quizQuestion) => {
                        return <QuizQuestion key={quizQuestion.id} onAnswerSelected={onAnswerSelected} quiz={linkedContent} currentQuizQuestion={currentQuestion} quizQuestion={quizQuestion} />;
                    })}
                </div>
                <div id={"quizname"} className={"quizname hidden"} />
            </div>
        );

        // If we have a result
    } else {
        return <QuizResult quizResult={result} />;
    }
};

export const StaticQuizPopupModule = withAnalytics(withContentNavigator(StaticQuizPopupModuleDecorated));
