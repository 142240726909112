import { urlIsAbsolute } from "./urlIsAbsolute";

export const addImageURLToList = (url: string, list: string[] = []): string[] => {
    //Ignore empty urls, otherwise the logic will return the first image from the manifest.
    if (url === "") {
        return list;
    }

    if (urlIsAbsolute(url)) {
        if (!list.includes(url)) {
            list.push(url);
        }

        return list;
    } else {
        throw new Error(`Invalid image URL "${url}`);
    }
};
