import React, { CSSProperties, PropsWithChildren } from "react";
import { LoadedContent } from "../../../../common/types/Data/Content";
import { File } from "../../../../common/types/Data/File";
import { buildOptionalFileBackgroundCSS } from "../../../utils/buildOptionalFileBackgroundCSS";
import { ConditionalGenericThumbnail } from "./ConditionalGenericThumbnail";

type Props = {
    entity: LoadedContent;
    file: File | undefined;
    classNames?: string[];
    style?: CSSProperties;
    noConditional?: boolean;
    href?: string;
};

export const ModulePreviewImage = (props: PropsWithChildren<Props>) => {
    const { file, entity, children, classNames: optionalClassNames, style: optionalStyle, noConditional, href } = props;

    const classNames = [...(optionalClassNames || []), "module-preview-image"];

    const style = optionalStyle || buildOptionalFileBackgroundCSS(file);

    if (href) {
        return (
            <a style={style} className={classNames.join(" ")} href={href} target={"_blank"} rel={"noreferrer"}>
                {noConditional ? undefined : <ConditionalGenericThumbnail entity={entity} thumbnail={file} />}
                {children}
            </a>
        );
    }
    return (
        <div style={style} className={classNames.join(" ")}>
            {noConditional ? undefined : <ConditionalGenericThumbnail entity={entity} thumbnail={file} />}
            {children}
        </div>
    );
};
