import React, { ComponentType, PropsWithChildren } from "react";
import { MinimumPopupProps } from "../../hocs/ContentNavigator/MinimumPopupProps";
import { PopupBody } from "./PopupBody";

type Props<TPopupProps extends MinimumPopupProps = MinimumPopupProps> = {
    visible: boolean;
    contentType: ComponentType<TPopupProps>;
    popupProps: TPopupProps;
    showRelatedContent: boolean;
    shortHeader?: string;
    header: string;
    onClose: () => void;
};

const getColourTag = (popupProps: MinimumPopupProps): string => {
    if (popupProps.entity.type) {
        const type = popupProps.entity.type;
        switch (type) {
            case "video":
            case "downloadable":
            case "downloadableList":
            case "external_content":
            case "image":
            case "imageGallery":
                return "bgYellow";
            case "newsFeed":
            case "charProfile":
            case "text":
                return "bgRed";
            case "leafletMap":
            case "locProfile":
            case "quiz":
                return "bgGreen";
        }
    }

    return "noColor";
};

export const Popup = (props: PropsWithChildren<Props>) => {
    const { contentType, visible, popupProps, showRelatedContent, shortHeader, header, onClose } = props;

    const { entity } = popupProps;
    const elementName = entity.id;
    const classNames: string[] = ["popup", `${elementName}`, getColourTag(popupProps), `popup-type-${entity.type}`];
    classNames.push(visible ? "active" : "inactive");

    const element = React.createElement(contentType, { ...popupProps, key: elementName });

    return (
        <PopupBody classNames={classNames} header={header} shortHeader={shortHeader} showRelatedContent={showRelatedContent} entity={entity} onClose={onClose}>
            {element}
        </PopupBody>
    );
};
