import { ComponentLoadState } from "../../common/types/Data/ComponentLoadState";

/**
 * Builds the array of CSS class names to apply to the component.
 *
 * @param {boolean} isBig
 * @param {ComponentLoadState} loadState
 * @param {string} contentType
 * @param {string[]} extraClasses
 *
 * @returns {string[]}
 */
export const buildComponentModuleCSSClasses = (isBig: boolean, loadState: ComponentLoadState, contentType?: string, extraClasses?: string[]): string[] => {
    const classes = ["ComponentModule", `component-${`${contentType || ""}`.split(":").join("-")}`];

    if (isBig) {
        classes.push("big");
    }

    if (loadState === ComponentLoadState.FetchingData || loadState === ComponentLoadState.PreloadingAssets) {
        classes.push("is-requesting");
    } else {
        classes.push("is-not-requesting");
    }

    switch (loadState) {
        case ComponentLoadState.Errored:
            classes.push("did-error");
            break;
        case ComponentLoadState.Loaded:
            classes.push("is-loaded");
            break;
        case ComponentLoadState.PreloadingAssets:
            classes.push("is-preloading-assets");
            break;
        case ComponentLoadState.FetchingData:
            classes.push("is-fetching-data");
            break;
        case ComponentLoadState.Pending:
            classes.push("is-pending");
            break;
    }

    if (!!extraClasses) {
        classes.push(...extraClasses);
    }

    return classes;
};
