import React, { PropsWithChildren } from "react";
import { PagePost } from "../../../../common/Facebook/PagePost";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { withActions, WithActions } from "../../../hocs/withActions";

export type Props = BaseComponentProps<PagePost> & {};

const FacebookLinkPopupModuleDecorated = (props: PropsWithChildren<Props & WithActions>) => {
    return (
        //@todo: Extract this link into a pure form instead of routing through FB so we can embed it.
        <iframe src={props.entity.linkedContent.attachments.data[0].url} />
    );
};

export const FacebookLinkPopupModule = withActions(FacebookLinkPopupModuleDecorated);
