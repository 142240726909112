import React, { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { ComponentLoadState } from "../../../common/types/Data/ComponentLoadState";
import { LoadedContent } from "../../../common/types/Data/Content";
import { LinkedContent } from "../../../common/types/Data/LinkedContent";
import { withModuleDataFetcher, WithModuleDataFetcher } from "../../hocs/withModuleDataFetcher";
import { Engagement } from "./Engagement";
import { BaseComponentProps } from "../../../common/types/Data/BaseComponentProps";
import { NewContent } from "./NewContent";
import { buildComponentModuleCSSClasses } from "../../utils/buildComponentModuleCSSClasses";
import { buildComponentModuleStyle } from "../../utils/buildComponentModuleStyle";
import { renderAndPreloadComponent } from "../../utils/renderAndPreloadComponent";
import { renderComponent } from "../../utils/renderComponent";
import { selectComponentLoaderStyle } from "../../utils/selectComponentLoaderStyle";
import { shouldShowComponentModuleLoader } from "../../utils/shouldShowComponentModuleLoader";
import { Loader } from "./Loader";
import { ModuleOverlay } from "./ModuleOverlay";

type Props<TLinkedContent extends LinkedContent> = {
    big: boolean;
    contentId: string;
    showPanel?: boolean;
    gridSlot?: string;
    useDetailModule?: boolean;
    entity?: LoadedContent<TLinkedContent>;
    extraClasses?: string[];
    staggerIndex?: number;
    deepLinked?: boolean;
    nested?: boolean;
    isSubModule?: boolean;
    isLarge?: boolean;
};

const ComponentModuleDecorated = <TLinkedContent extends LinkedContent, TComponentProps extends BaseComponentProps<TLinkedContent>>(props: PropsWithChildren<Props<TLinkedContent> & WithModuleDataFetcher>) => {
    const { moduleDataFetcher, contentId, gridSlot, showPanel, big, useDetailModule, entity, extraClasses, staggerIndex, deepLinked, nested, isSubModule, isLarge } = props;

    let initialLoadState = ComponentLoadState.Pending;
    let initialLoadedEntity;
    let initialRenderedComponent;
    if (entity) {
        initialLoadState = ComponentLoadState.Loaded;
        initialLoadedEntity = entity;
        initialRenderedComponent = renderComponent<TLinkedContent, TComponentProps>(initialLoadedEntity, { useDetailModule, deepLinked, nested });
    }

    const [loadState, setLoadState] = useState<ComponentLoadState>(initialLoadState);
    const [loadedEntity, setLoadedEntity] = useState<LoadedContent | undefined>(initialLoadedEntity);
    const [renderedComponent, setRenderedComponent] = useState<ReactElement<TComponentProps> | undefined>(initialRenderedComponent);
    const placeholderMode = false;

    /**
     * Fetches the component module's data from the API, and renders the appropriate component into state.
     */
    const loadModuleData = async () => {
        try {
            if (loadState === ComponentLoadState.FetchingData || loadState === ComponentLoadState.PreloadingAssets) {
                // eslint-disable-next-line no-console
                console.warn(`[Component Module] Content ID ${contentId} is already requesting, is at state ${ComponentLoadState[loadState]}.`);
                return;
            }

            setLoadState(ComponentLoadState.FetchingData);

            // Request Backend
            let newLoadedEntity: LoadedContent<TLinkedContent>;
            if (!entity) {
                try {
                    newLoadedEntity = await moduleDataFetcher<TLinkedContent>(contentId);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(`Could not look up content for ID "${contentId}": ${error}`);
                    window.location.href = "/";
                    return;
                }
            } else {
                newLoadedEntity = entity;
            }

            setLoadedEntity(newLoadedEntity);

            setLoadState(ComponentLoadState.PreloadingAssets);

            const renderedComponent = await renderAndPreloadComponent<TLinkedContent, TComponentProps>(newLoadedEntity, { useDetailModule, deepLinked, nested, isLarge });

            setRenderedComponent(renderedComponent);
            setLoadState(ComponentLoadState.Loaded);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            setLoadState(ComponentLoadState.Errored);
        }
    };

    useEffect(() => {
        if (!entity) {
            loadModuleData().then();
        }
    }, [contentId, entity, useDetailModule]);

    // Set class to big
    const classes = buildComponentModuleCSSClasses(big, loadState, loadedEntity ? loadedEntity.type : undefined, extraClasses);
    const loaderImage = selectComponentLoaderStyle(big, isSubModule ? isSubModule : false, loadedEntity);

    return (
        <div className={classes.join(" ")} style={buildComponentModuleStyle(gridSlot, staggerIndex)}>
            {!placeholderMode ? (
                <>
                    <Engagement entity={loadedEntity} showPanel={!!showPanel && !useDetailModule} waitForInteraction={true}>
                        {renderedComponent}
                        <NewContent />
                        {loadedEntity !== undefined ? <ModuleOverlay tags={loadedEntity.contentTags} /> : undefined}
                        <Loader show={shouldShowComponentModuleLoader(loadState)} imageName={loaderImage} />
                    </Engagement>
                </>
            ) : (
                `${contentId}`
            )}
        </div>
    );
};

export const ComponentModule = withModuleDataFetcher(ComponentModuleDecorated);
