/**
 * Breaks down a grid structure template string to a 2D array of numbers representing the grid slots.
 *
 * @param {string} gridStructureTemplate
 *
 * @returns {GridStructure}
 */
import { GridStructure } from "./GridStructure";

export const deriveGridStructure = (gridStructureTemplate: string): GridStructure => {
    const gridStructure = gridStructureTemplate.trim();

    return gridStructure.split("\n").map((line) => {
        return line
            .trim()
            .split(" ")
            .map((number) => {
                return Number.parseInt(number, 10);
            });
    });
};
