import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { InteractiveMap } from "../../../../common/types/Data/InteractiveMap";
import { Props, StaticMapModule } from "./StaticMapModule";

export const moduleLoader: ComponentModuleLoader<InteractiveMap, Props> = {
    baseModule: StaticMapModule,
    popupModule: StaticMapModule,
    payloadExtractor: (entity) => {
        return {
            entity,
        };
    },
};
