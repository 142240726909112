import React, { PropsWithChildren, useEffect } from "react";
import { withResetGridScroll, WithResetGridScroll } from "../hocs/withResetGridScroll";
import { withState, WithState } from "../hocs/withState";
import { Gradient } from "./Decor/Gradient";
import { PageDecor } from "./Decor/PageDecor";
import { PageHeader } from "./Header/PageHeader";
import { TagSelector } from "./Header/TagSelector";
import { PopupRouteHandler } from "./Popup/PopupRouteHandler";
import { Router } from "./Router";
import { BackgroundElements } from "./Decor/BackgroundElements";

type Props = {
    path: string;
    redirectMode?: boolean;
};

const MainDecorated = (props: PropsWithChildren<Props & WithResetGridScroll & WithState>) => {
    const {
        children,
        resetGridScroll,
        redirectMode,
        state: { currentTagCollection },
    } = props;

    useEffect(() => {
        resetGridScroll();
    }, [currentTagCollection]);

    return (
        <Router>
            <PageDecor />
            <PageHeader redirectMode={redirectMode} />
            <div className={"component-grid-parent"}>
                <Gradient />
                <TagSelector />
                <PopupRouteHandler />
                {children}
            </div>
            <BackgroundElements />
        </Router>
    );
};

export const Main = withResetGridScroll(withState(MainDecorated));
