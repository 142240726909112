import React, { PropsWithChildren } from "react";
import { File } from "../../../../common/types/Data/File";
import { BackgroundSize, buildOptionalFileBackgroundCSS } from "../../../utils/buildOptionalFileBackgroundCSS";

type Props = {
    file: File;
    classNames?: string[];
    backgroundSize?: BackgroundSize;
};

export const StandardImageBox = (props: PropsWithChildren<Props>) => {
    const { file, classNames: propertyClassNames, backgroundSize: propertyBackgroundSize, children } = props;

    const classNames = [...(propertyClassNames || []), "standard-image", "noselect"];
    const backgroundSize = propertyBackgroundSize || "cover";
    const { credit } = file;
    const style = buildOptionalFileBackgroundCSS(file, backgroundSize);

    return (
        <div className={classNames.join(" ")} style={style}>
            <div className={"imageCredit"}>{credit}</div>
            {children}
        </div>
    );
};
