import React, { PropsWithChildren } from "react";
import { WithThumbnailFile } from "../../../../common/types/Data/File";
import { QuizResult as QuizResultData } from "../../../../common/types/Data/QuizResult";
import { StandardImageBox } from "../Common/StandardImageBox";

type Props = {
    quizResult: QuizResultData & WithThumbnailFile;
};

export const QuizResult = (props: PropsWithChildren<Props>) => {
    const { quizResult } = props;

    const { thumbnailFile } = quizResult;

    return (
        <div className={"quiz-result"}>
            {thumbnailFile ? <StandardImageBox file={thumbnailFile} backgroundSize={"contain"} classNames={["result-image"]} /> : undefined}
            <div className={"result-text noselect"}>
                <div className={"result-label"}>{quizResult.label}</div>
                <div className={"result-detail"}>{quizResult.detail}</div>
            </div>
        </div>
    );
};
