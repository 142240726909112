import React, { CSSProperties, PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { PopulatedVideo } from "../../../../common/types/Data/PopulatedVideo";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { buildOptionalFileBackgroundCSS } from "../../../utils/buildOptionalFileBackgroundCSS";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ModulePreviewImage } from "../Common/ModulePreviewImage";

export type Props = BaseComponentProps<PopulatedVideo> & {
    flexSize?: number;
};

const buildBackgroundStyle = (video: PopulatedVideo): CSSProperties => {
    if (video.thumbnailFile) {
        return buildOptionalFileBackgroundCSS(video.thumbnailFile);
    } else {
        const youtubeElements = video.url.match(/^https:\/\/www\.youtube\.com\/embed\/(.*)$/i);
        if (youtubeElements && youtubeElements.length === 2) {
            return {
                backgroundImage: `url(https://img.youtube.com/vi/${youtubeElements[1]}/hqdefault.jpg)`,
                backgroundSize: `cover`,
            };
        }
    }

    return {};
};

const StaticVideoBaseModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics & WithContentNavigator>) => {
    const {
        entity,
        contentNavigator,
        nested,
        analyticsUtils: { trackClickModuleEvent },
    } = props;

    const onClick = () => {
        trackClickModuleEvent(entity);
        contentNavigator.open("video", { entity }, { nested });
    };

    const style = buildBackgroundStyle(entity.linkedContent);

    return (
        <ModulePreview classNames={["VideoModule", "GalleryImage"]} onClick={onClick}>
            <ModulePreviewHeader className={"video"} label={entity.label} />
            <ModulePreviewImage entity={entity} file={entity.linkedContent.thumbnailFile} style={style} noConditional={true} />
        </ModulePreview>
    );
};

export const StaticVideoBaseModule = withAnalytics(withContentNavigator(StaticVideoBaseModuleDecorated));
