import React, { CSSProperties, PropsWithChildren } from "react";
import { PopulatedContent } from "../../../common/types/Data/Content";

interface IProps {
    error: Error;
    entity: PopulatedContent;
}

export const BadModule = (props: PropsWithChildren<IProps>) => {
    const { entity, error } = props;

    const style: CSSProperties = {
        display: "block",
        border: "1px solid red",
        background: "#faa",
        color: "red",
        overflowWrap: "break-word",
    };

    return (
        <div className={"bad-module"}>
            <h2>Bad Module</h2>
            <h3>Error</h3>
            <code style={style}>{error.message}</code>
            <h3>Data</h3>
            <code style={style}>{JSON.stringify(entity)}</code>
        </div>
    );
};
