import { Content } from "../types/Data/Content";
import { BigModulePattern } from "./BigModulePattern";

export const arrangeContent = (content: Content[], bigModulePattern: BigModulePattern = []): Content[] => {
    if (bigModulePattern.length < 2) {
        return content;
    }

    const output = content;

    const largeCount = output.reduce((runningLargeCount, next) => {
        if (next.forceLarge) {
            ++runningLargeCount;
        }
        return runningLargeCount;
    }, 0);

    if (largeCount > 0) {
        let nextBig = bigModulePattern[0];
        let bigIndex = 1;
        let shifts = 0;
        const lockedModules: number[] = [];

        for (let i = 0; i < output.length; ++i) {
            if (
                shifts < largeCount &&
                output[i].forceLarge &&
                nextBig <= output.length &&
                lockedModules.find((entry) => {
                    return entry === i;
                }) == undefined
            ) {
                const putIndex = nextBig - 1;
                let removeIndex = i;

                if (nextBig <= i) {
                    removeIndex = i + 1;
                }

                //Insert the module at the correct index to be large, then remove it from the original position.
                output.splice(putIndex, 0, output[i]);
                lockedModules.push(nextBig - 1);

                let swapIndex = removeIndex;
                for (let j = removeIndex + 1; j < output.length; ++j) {
                    //If the current index is not locked, swap them.
                    if (
                        lockedModules.find((index) => {
                            return index === j;
                        }) == undefined
                    ) {
                        [output[swapIndex], output[j]] = [output[j], output[swapIndex]];
                        swapIndex = j;
                    }
                }

                output.splice(swapIndex, 1);

                //If we shifted a module, reduce the iterator so we don't skip any;
                --i;
                ++shifts;

                //Find the next big module slot.
                nextBig += bigModulePattern[bigIndex];

                ++bigIndex;
                bigIndex %= bigModulePattern.length;

                if (bigIndex === 0) {
                    nextBig += bigModulePattern[bigIndex];
                    ++bigIndex;
                }
            }
        }
    }

    return output;
};
