import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { PopulatedImage } from "../../../../common/types/Data/PopulatedImage";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { BackgroundSize } from "../../../utils/buildOptionalFileBackgroundCSS";

export type Props = BaseComponentProps<PopulatedImage> & {
    url: string;
    size?: BackgroundSize;
};

const StaticImageBaseModuleDecorated = (props: PropsWithChildren<Props & WithContentNavigator>) => {
    const { entity, size, contentNavigator, nested } = props;

    const clickedOn = () => {
        contentNavigator.open("image", { entity, size }, { header: entity.label, nested });
    };

    const divStyle = {
        //flex: "1 1 100%",
        //height: "100%",
    };

    //Set background image of image div
    const backgroundImage = {
        backgroundImage: `url('${entity.linkedContent.thumbnailFile?.path}')`,
    };

    return (
        <div className={"GalleryImage modulecontent"} style={divStyle} onClick={clickedOn}>
            <div style={backgroundImage} className={"image noselect"} />
        </div>
    );
};

export const StaticImageBaseModule = withContentNavigator(StaticImageBaseModuleDecorated);
