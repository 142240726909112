import debounce from "debounce";
import React, { PropsWithChildren } from "react";
import { useMediaQuery } from "react-responsive";

type ResponsiveStateQueries = {
    [key: string]: string;
};

type ResponsiveStateQueryResults<TResponsiveStateQueries extends ResponsiveStateQueries> = Record<keyof TResponsiveStateQueries, boolean>;

interface IProps<TResponsiveStateQueries extends ResponsiveStateQueries> {
    queries: TResponsiveStateQueries;
    onChange: (payload: ResponsiveStateQueryResults<TResponsiveStateQueries>) => void;
}

export const ResponsiveStateListener = <TResponsiveStateQueries extends ResponsiveStateQueries>(props: PropsWithChildren<IProps<TResponsiveStateQueries>>) => {
    const { onChange: onChangePassed, queries } = props;

    const onChange = debounce(onChangePassed, 100);

    const internalState: ResponsiveStateQueryResults<TResponsiveStateQueries> = Object.assign(
        {},
        ...Object.entries(props.queries).map(([key]) => {
            return {
                [key]: false,
            };
        }),
    );

    const collect = (): ResponsiveStateQueryResults<TResponsiveStateQueries> => {
        Object.entries(queries).map(([key, query]: [keyof TResponsiveStateQueries, string]) => {
            const value = useMediaQuery({ query }, {}, (newValue) => {
                const oldValue = internalState[key];
                if (oldValue != newValue) {
                    internalState[key] = newValue;
                    onChange(internalState);
                }
            });
            internalState[key] = value;

            return {
                [key]: value,
            };
        });

        return internalState;
    };

    collect();
    onChange(internalState);

    return <></>;
};
