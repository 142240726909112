import React, { PropsWithChildren } from "react";
import { LoadedPseudoContent } from "../../../../common/types/Data/Content";
import { WithFile } from "../../../../common/types/Data/File";
import { HasNumericID } from "../../../../common/types/Data/HasNumericID";
import { HasTimestamps } from "../../../../common/types/Data/HasTimestamps";
import { BackgroundSize, buildOptionalFileBackgroundCSS } from "../../../utils/buildOptionalFileBackgroundCSS";

export type Props = {
    entity: LoadedPseudoContent<WithFile & HasNumericID & HasTimestamps>;
    nested?: boolean;
    size?: BackgroundSize;
};

export const PopupImage = (props: PropsWithChildren<Props>) => {
    const { entity, size } = props;
    const { file } = entity.linkedContent;

    //Set background image of image div
    const backgroundImage = buildOptionalFileBackgroundCSS(file, size || "contain");

    return <div style={backgroundImage} className={"popupimage noselect"} />;
};
