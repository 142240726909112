import { Action } from "redux";
import { ReduxActionType } from "../../../common/types/ReduxActionType";
import { actionReducer } from "../actionReducer";
import { initialState } from "../initialState";

export type SetCurrentModuleAction = {
    type: ReduxActionType.SetCurrentModule;
    currentModule: number | string | undefined;
};

export const createSetCurrentModuleAction = (currentModule?: number | string): SetCurrentModuleAction => {
    return {
        type: ReduxActionType.SetCurrentModule,
        currentModule,
    };
};

export const isSetCurrentModuleAction = (action: Action): action is SetCurrentModuleAction => {
    return action.type === ReduxActionType.SetCurrentModule;
};

export const setCurrentModuleReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        const { currentModule } = action;

        return {
            ...previousState,
            currentModule,
        };
    },
    isSetCurrentModuleAction,
);
