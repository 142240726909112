import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { CharacterProfile } from "../../../../common/types/Data/CharacterProfile";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { buildOptionalFileBackgroundCSS } from "../../../utils/buildOptionalFileBackgroundCSS";

export type Props = BaseComponentProps<CharacterProfile> & {
    flexSize: number;
};

const StaticCharacterProfileBaseModuleDecorated = (props: PropsWithChildren<Props & WithContentNavigator & WithAnalytics>) => {
    const {
        entity,
        flexSize,
        contentNavigator,
        nested,
        analyticsUtils: { trackClickModuleEvent },
    } = props;

    // Called when clicked on
    const clickedOn = () => {
        trackClickModuleEvent(entity);
        contentNavigator.open("charProfile", { entity, flexSize }, { header: entity.label, nested });
    };

    const divStyle = {
        flex: flexSize,
    };

    const image = entity.linkedContent.images[0];

    //Set background image of image div
    const backgroundImage = buildOptionalFileBackgroundCSS(image.thumbnailFile);

    return (
        <div className={"info"} style={divStyle} onClick={clickedOn}>
            <div style={backgroundImage} className={"charimage noselect modulecontent"} />
        </div>
    );
};

export const StaticCharacterProfileBaseModule = withContentNavigator(withAnalytics(StaticCharacterProfileBaseModuleDecorated));
