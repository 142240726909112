import { LoadedContent } from "../../../common/types/Data/Content";
import { ErrorMessage } from "../../../common/types/Data/ErrorMessage";

export const content404Error: LoadedContent<ErrorMessage> = {
    id: "error404",
    createdAt: "",
    updatedAt: "",
    contentTags: [],
    type: "error",
    titleTextCopyId: undefined,
    isPublic: 1,
    title_copy_id: "",
    publishedAt: "",
    forceLarge: 1,
    content_id: "error404",
    destroyWhenChangingType: undefined,
    expands: 0,
    label: "Error 404",
    localFileName: undefined,
    priority: 500,
    shortLabel: "Error 404",
    title_copy: {
        updatedAt: "",
        createdAt: "",
        text: "",
        locale: "",
        copy_id: "",
        id: 0,
    },
    linkedContent: {
        message: "Sorry, that content does not exist! Let's go back to the home page!",
        statusCode: 404,
    },
};
