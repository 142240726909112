import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { YoutubeSearchResult } from "../../../../common/Youtube/YoutubeSearchResult";
import { Props, YoutubeVideoBaseModule } from "./YoutubeVideoBaseModule";
import { YoutubeVideoPopupModule } from "./YoutubeVideoPopupModule";

export const moduleLoader: ComponentModuleLoader<YoutubeSearchResult, Props> = {
    baseModule: YoutubeVideoBaseModule,
    popupModule: YoutubeVideoPopupModule,
    payloadExtractor: (entity) => {
        return {
            entity,
        };
    },
    getSocialTags: (entity) => {
        return {
            description: entity.linkedContent.snippet.description,
            name: entity.linkedContent.snippet.title,
            previewImage: entity.linkedContent.snippet.thumbnails.high.url,
        };
    },
};
