import React, { PropsWithChildren } from "react";
import { Dropbear } from "./Dropbear";
import { Leaf } from "./Leaf";

type Props = {};

export const PageDecor = (props: PropsWithChildren<Props>) => {
    const { children } = props;
    return (
        <>
            <Dropbear id={"dropBear1"} />
            <Dropbear id={"dropBear2"} />
            <Leaf id={"leafArt1"} />
            <Leaf id={"leafArt2"} overlayId={"leafOverlay2"} />
            {children}
        </>
    );
};
