import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { CharacterProfile } from "../../../../common/types/Data/CharacterProfile";
import { buildOptionalFileBackgroundCSS } from "../../../utils/buildOptionalFileBackgroundCSS";

type Props = BaseComponentProps<CharacterProfile>;

export const StaticCharacterProfilePopupModule = (props: PropsWithChildren<Props>) => {
    const { entity } = props;
    const image = entity.linkedContent.images[0];

    const backgroundImage = buildOptionalFileBackgroundCSS(image.file, "contain");

    return (
        <div className={"characterProfileViewer"}>
            <div style={backgroundImage} className={"popupimage noselect"} />
            <p>{entity.linkedContent.label}</p>
        </div>
    );
};
