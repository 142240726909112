import { ComponentType } from "react";
import { ContentType } from "../../../common/types/Data/ContentType";
import { ContentNavigatorConfig } from "./ContentNavigatorConfig";
import { contentNavigatorHistoryFactory } from "./contentNavigatoryHistoryFactory";
import { ContentProperties } from "./ContentProperties";
import { MinimumPopupProps } from "./MinimumPopupProps";
import { PopupConfig } from "./PopupConfig";
import { withReactRouterDOMContentNavigatorFactory } from "./withReactRouterDOMContentNavigatorFactory";

export type ContentNavigatorGotoId = (id: number | string) => void;

export type ContentNavigator = {
    open: <TProps extends MinimumPopupProps>(contentType: ContentType, popupProps: TProps, popupConfig?: PopupConfig) => void;
    openWith: <TProps extends MinimumPopupProps>(popupType: ComponentType<TProps>, popupProps: TProps, popupConfig?: PopupConfig) => void;
    gotoId: ContentNavigatorGotoId;
    close: (pushHistory?: boolean) => void;
    configure: (config: ContentNavigatorConfig) => void;

    /**
     * Override the gotoId behaviour.
     * @param newGotoId
     * @returns {Function} Returns a function that can be invoked to cancel the override
     */
    overrideGotoId: (newGotoId: ContentNavigatorGotoId) => () => void;
    pluckLastContentProperties: (id: number | string) => ContentProperties | undefined;
};

export type WithContentNavigator = {
    contentNavigator: ContentNavigator;
};

const contentNavigator = contentNavigatorHistoryFactory();
export const withContentNavigator = withReactRouterDOMContentNavigatorFactory(contentNavigator);
