import React, { PropsWithChildren } from "react";

type Props = {
    hide?: boolean;
};

export const CreditsMobile = (props: PropsWithChildren<Props>) => {
    const hide: string = props.hide ? "hide" : "";

    return (
        <>
            <div className={`creditsMobile ${hide}`}>
                <ul className="logos">
                    <li>
                        <a href="https://www.odinseyeent.com/">
                            <div className="logoLinks" id="odinsEye" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.screenaustralia.gov.au/">
                            <div className="logoLinks" id="screenAus" />
                        </a>
                    </li>
                    <li>
                        <a href="https://screenqueensland.com.au/">
                            <div className="logoLinks" id="screenQld" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.universalpictures.com.au/">
                            <div className="logoLinks" id="universal" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.likeaphoton.com/">
                            <div className="logoLinks" id="lapc" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.ponyuppicturestudios.com/">
                            <div className="logoLinks" id="ponyUp" />
                        </a>
                    </li>
                </ul>
                <p className="copyright">Copyright © Like a Photon Creative 2019</p>
            </div>
        </>
    );
};
