import { BaseComponentProps } from "../../common/types/Data/BaseComponentProps";
import { ComponentModuleLoader } from "../../common/types/Data/ComponentModuleLoader";
import { LinkedContent } from "../../common/types/Data/LinkedContent";
import { moduleLoader as characterProfileModuleLoader } from "../components/Modules/StaticCharacterProfile/moduleLoader";
import { moduleLoader as downloadModuleLoader } from "../components/Modules/StaticDownloadable/moduleLoader";
import { moduleLoader as imageModuleLoader } from "../components/Modules/StaticImage/moduleLoader";
import { moduleLoader as locationProfileModuleLoader } from "../components/Modules/StaticLocationProfile/moduleLoader";
import { moduleLoader as quizModuleLoader } from "../components/Modules/StaticQuiz/moduleLoader";
import { moduleLoader as videoModuleLoader } from "../components/Modules/StaticVideo/moduleLoader";
import { moduleLoader as mapModuleLoader } from "../components/Modules/StaticMap/moduleLoader";
import { moduleLoader as imageGalleryModuleLoader } from "../components/Modules/StaticImageGallery/moduleLoader";
import { moduleLoader as facebookVideoModuleLoader } from "../components/Modules/FacebookVideo/moduleLoader";
import { moduleLoader as youtubeVideoModuleLoader } from "../components/Modules/YoutubeVideo/moduleLoader";
import { moduleLoader as facebookGalleryModuleLoader } from "../components/Modules/FacebookGallery/moduleLoader";
import { moduleLoader as facebookLinkModuleLoader } from "../components/Modules/FacebookLink/moduleLoader";
import { moduleLoader as facebookImageModuleLoader } from "../components/Modules/FacebookImage/moduleLoader";
import { moduleLoader as errorModuleLoader } from "../components/Modules/Error/moduleLoader";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericComponentModuleLoader = ComponentModuleLoader<any, any, any>;

const moduleLoaderMap = new Map<string, GenericComponentModuleLoader>();
moduleLoaderMap.set("facebook:video", facebookVideoModuleLoader);
moduleLoaderMap.set("facebook:gallery", facebookGalleryModuleLoader);
moduleLoaderMap.set("facebook:link", facebookLinkModuleLoader);
moduleLoaderMap.set("facebook:image", facebookImageModuleLoader);
moduleLoaderMap.set("youtube:video", youtubeVideoModuleLoader);
moduleLoaderMap.set("image", imageModuleLoader);
moduleLoaderMap.set("video", videoModuleLoader);
moduleLoaderMap.set("quiz", quizModuleLoader);
moduleLoaderMap.set("downloadable", downloadModuleLoader);
moduleLoaderMap.set("charProfile", characterProfileModuleLoader);
moduleLoaderMap.set("locProfile", locationProfileModuleLoader);
moduleLoaderMap.set("interactiveMap", mapModuleLoader);
moduleLoaderMap.set("imageGallery", imageGalleryModuleLoader);
moduleLoaderMap.set("error", errorModuleLoader);

export const getModuleLoader = <TLinkedContent extends LinkedContent = LinkedContent, TComponentProps extends BaseComponentProps<TLinkedContent> = BaseComponentProps<TLinkedContent>>(type: string): ComponentModuleLoader<TLinkedContent, TComponentProps> | undefined => {
    return moduleLoaderMap.get(type);
};
