import React, { PropsWithChildren } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

interface IProps {}

const isBrowser = typeof window !== "undefined";

export const Router = (props: PropsWithChildren<IProps>) => {
    const { children } = props;
    return isBrowser ? (
        <BrowserRouter>
            <Switch>
                <Route exact path={"/"} />
                <Route path={"/content?id=:id"} />
            </Switch>
            {children}
        </BrowserRouter>
    ) : (
        <>{children}</>
    );
};
