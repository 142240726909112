import { CRS, icon, imageOverlay, LatLngBoundsExpression, map as leafletMap, Map as LeafletMap, Marker as LeafletMarker } from "leaflet";
import React, { ComponentType, PropsWithChildren } from "react";
import { LoadedContent } from "../../common/types/Data/Content";
import { PopulatedLocationProfile } from "../../common/types/Data/PopulatedLocationProfile";

type InteractiveMapUnmountData = {
    target: HTMLDivElement;
    id: string | number;
};

type InteractiveMapClickCallback = (clickedEntity: LoadedContent<PopulatedLocationProfile>) => void;

export type InteractiveMapMountData = InteractiveMapUnmountData & {
    onClickCallback: InteractiveMapClickCallback;
    entities: LoadedContent<PopulatedLocationProfile>[];
};

type InteractiveMapHooks = {
    mount: (data: InteractiveMapMountData) => void;
    unmount: (data: InteractiveMapUnmountData) => void;
};

export type WithMapHooks = {
    mapHooks: InteractiveMapHooks;
};

const withMapHooksFactory = () => {
    const makeMapHooks = (): InteractiveMapHooks => {
        const leafletMapTargets = new Map<HTMLElement, LeafletMap>();

        const baseBounds: LatLngBoundsExpression = [
            [0, 0],
            [1501, 3000],
        ];

        const getOrCreateLeafletMap = (key: string, target: HTMLDivElement): LeafletMap => {
            const existingMap = leafletMapTargets.get(target);
            if (existingMap) {
                return existingMap;
            }
            const bounds: LatLngBoundsExpression = [...baseBounds];
            const newMap = leafletMap(target, {
                crs: CRS.Simple,
                maxBounds: bounds,
                maxBoundsViscosity: 1,
                zoomSnap: 0,
                maxZoom: 0.8, //1.2
                minZoom: -0.1,
                zoomDelta: 0.3,
                zoomAnimation: false,
                doubleClickZoom: false,
            });
            leafletMapTargets.set(target, newMap);
            return newMap;
        };

        const leafMapInitialise = (payload: InteractiveMapMountData) => {
            try {
                const leaf = getOrCreateLeafletMap(`${payload.id}`, payload.target);

                const { onClickCallback } = payload;

                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const mapPath = require("../images/MapBackground03.jpg");
                const bounds: LatLngBoundsExpression = [...baseBounds];

                imageOverlay(mapPath, bounds).addTo(leaf);
                leaf.fitBounds(bounds);
                leaf.setZoom(-0.4);
                leaf.setView([775, 1500], 0);

                for (const entity of payload.entities) {
                    const markerIcon = icon({ iconUrl: entity.linkedContent.iconFile?.path || require("../images/Files/mapIcon.png"), iconSize: [90, 90], iconAnchor: [90, 90], tooltipAnchor: [0, 0], popupAnchor: [0, 0], shadowAnchor: [0, 0] });

                    const paneName = entity.label.replace(" ", "") + " custom-tooltip";
                    leaf.createPane(paneName);

                    const marker = new LeafletMarker([entity.linkedContent.positionY, entity.linkedContent.positionX], {
                        icon: markerIcon,
                        title: entity.label,
                    });
                    marker.bindTooltip(entity.label, { className: "mapTooltip", direction: "center", permanent: true, offset: [45, 110], interactive: true, pane: paneName });
                    marker.on("click", () => {
                        onClickCallback(entity);
                    });
                    marker.addTo(leaf);
                }
            } catch (error) {
                // @todo report any errors to analytics
                // eslint-disable-next-line no-console
                console.error(error, error.stack);
            }
        };

        const leafletMapUninitialise = (payload: InteractiveMapUnmountData) => {
            try {
                const leaf = getOrCreateLeafletMap(`${payload.id}`, payload.target);
                leaf.remove();
                leafletMapTargets.delete(payload.target);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.warn("[Leaflet Map]", error);
            }
        };

        const mount = (data: InteractiveMapMountData) => {
            leafMapInitialise(data);
        };

        const unmount = (data: InteractiveMapUnmountData) => {
            leafletMapUninitialise(data);
        };

        return { mount, unmount };
    };

    const mapHooks = makeMapHooks();

    return <TProps,>(Component: ComponentType<TProps & WithMapHooks>): ComponentType<TProps> => {
        const ComponentWithMapHooks = (props: PropsWithChildren<TProps>) => {
            return <Component {...props} mapHooks={mapHooks} />;
        };

        return ComponentWithMapHooks;
    };
};

export const withMapHooks = withMapHooksFactory();
