import { GridStructure } from "./GridStructure";

export const findMaxIndex = (gridStructure: GridStructure): number => {
    let maxIndex = 0;

    gridStructure.forEach((row) => {
        row.forEach((index) => {
            if (Math.abs(index) > maxIndex) {
                maxIndex = Math.abs(index);
            }
        });
    });

    return maxIndex;
};
