import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { PopulatedQuiz } from "../../../../common/types/Data/PopulatedQuiz";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ModulePreviewImage } from "../Common/ModulePreviewImage";

export type Props = BaseComponentProps<PopulatedQuiz>;

const StaticQuizBaseModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics & WithContentNavigator>) => {
    const {
        entity,
        analyticsUtils: { trackClickModuleEvent },
        contentNavigator,
        nested,
    } = props;

    const onClick = async () => {
        await trackClickModuleEvent(entity);
        contentNavigator.open(
            "quiz",
            {
                entity,
                displayResultInline: true,
            },
            { showRelatedContent: false, header: entity.linkedContent.label, nested },
        );
    };

    return (
        <ModulePreview classNames={["quizinfo"]} onClick={onClick}>
            <ModulePreviewHeader className={"quiz"} label={entity.label} />
            <ModulePreviewImage entity={entity} file={entity.linkedContent.thumbnailFile} />
        </ModulePreview>
    );
};

export const StaticQuizBaseModule = withAnalytics(withContentNavigator(StaticQuizBaseModuleDecorated));
