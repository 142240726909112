import { CharacterProfile } from "../../../../common/types/Data/CharacterProfile";
import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { addFileURLToList } from "../../../utils/addFileURLToList";
import { Props, StaticCharacterProfileBaseModule } from "./StaticCharacterProfileBaseModule";
import { StaticCharacterProfilePopupModule } from "./StaticCharacterProfilePopupModule";

export const moduleLoader: ComponentModuleLoader<CharacterProfile, Props> = {
    baseModule: StaticCharacterProfileBaseModule,
    popupModule: StaticCharacterProfilePopupModule,
    payloadExtractor: (entity) => {
        let image = "";
        const images = entity.linkedContent.images;
        if (images && images.length > 0) {
            image = images[0].url;
        }

        return {
            imageName: image,
            header: entity.label,
            entity,
            flexSize: 1,
        };
    },
    getAssetURIs: (props: Props): string[] => {
        const { entity } = props;
        let output: string[] = [];

        const image = entity.linkedContent.images[0];
        output = addFileURLToList(image.thumbnailFile, output);

        return output;
    },
};
