import React, { PropsWithChildren } from "react";
import { DownloadIcon } from "./DownloadIcon";
import { ExpandButton } from "./ExpandButton";

type Props = {
    onClick?: () => void;
    classNames: string[];
    expandable?: boolean;
    downloadable?: boolean;
    href?: string;
};

export const ModulePreview = (props: PropsWithChildren<Props>) => {
    const { onClick, classNames, children, expandable, downloadable, href } = props;
    return (
        <div className={["module-preview", "modulecontent", expandable ? "expandable" : "", ...classNames].join(" ")} onClick={onClick}>
            {expandable ? <ExpandButton /> : undefined}
            {downloadable && href ? <DownloadIcon href={href} /> : undefined}
            {children}
        </div>
    );
};
