import React, { PropsWithChildren } from "react";
import { withResetGridScroll, WithResetGridScroll } from "../../hocs/withResetGridScroll";

type Props = {
    redirectMode?: boolean;
};

const PageHeaderDecorated = (props: PropsWithChildren<Props & WithResetGridScroll>) => {
    const { resetGridScroll, redirectMode } = props;

    const onClick = () => {
        if (redirectMode) {
            window.location.href = "/";
        } else {
            resetGridScroll();
        }
    };

    return (
        <div className="header">
            <div className="mobileHeader" />
            <div className="mobileHeaderFlex">
                <div className="tfscLogo" onClick={onClick} />
                <a className="hamburgerMenu" href={"#"}>
                    <div className="bars">
                        <span />
                        <span />
                        <span />
                    </div>
                </a>
            </div>
        </div>
    );
};

export const PageHeader = withResetGridScroll(PageHeaderDecorated);
