import { File } from "../../common/types/Data/File";
import { addImageURLToList } from "./addImageURLToList";

export const addFileURLToList = (file: File | undefined, list: string[]): string[] => {
    if (file) {
        return addImageURLToList(file.path, list);
    }

    return list;
};
