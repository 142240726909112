import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { PopulatedQuiz } from "../../../../common/types/Data/PopulatedQuiz";
import { generateGenericSocialTags } from "../../../utils/generateGenericSocialTags";
import { Props, StaticQuizBaseModule } from "./StaticQuizBaseModule";
import { StaticQuizPopupModule } from "./StaticQuizPopupModule";

export const moduleLoader: ComponentModuleLoader<PopulatedQuiz, Props> = {
    baseModule: StaticQuizBaseModule,
    popupModule: StaticQuizPopupModule,
    payloadExtractor: (entity, config) => {
        const { deepLinked, nested } = config;
        return { entity, displayResultInline: !!deepLinked || !!nested };
    },
    getAssetURIs: (props) => {
        const output: string[] = [];

        const {
            entity: {
                linkedContent: { thumbnailFile },
            },
        } = props;
        if (thumbnailFile) {
            // addFileURLToList(thumbnailFile, output);
        }

        return output;
    },
    getSocialTags: generateGenericSocialTags,
};
