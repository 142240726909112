import React, { PropsWithChildren } from "react";
import { PopulatedVideo } from "../../../../common/types/Data/PopulatedVideo";
import { PopupComponentProps } from "../../../../common/types/Data/PopupComponentProps";

type Props = PopupComponentProps<PopulatedVideo>;

export const StaticVideoPopupModule = (props: PropsWithChildren<Props>) => {
    const { entity } = props;
    return <iframe src={entity.linkedContent.url} className={"popupvideo"} frameBorder={"0"} allow={"accelerometer; fullscreen; autoplay; encrypted-media; gyroscope; picture-in-picture"} />;
};
