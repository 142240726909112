import React, { PropsWithChildren } from "react";

type Props = {
    href: string;
};

export const DownloadIcon = (props: PropsWithChildren<Props>) => {
    const { children, href } = props;
    return (
        <a className="downloadIcon" href={href} target={"_blank"} rel={"noreferrer"}>
            {children}
        </a>
    );
};
