import React, { ComponentType, PropsWithChildren } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ContentNavigator, WithContentNavigator } from "./withContentNavigator";

const isBrowser = typeof window !== "undefined";

export const withReactRouterDOMContentNavigatorFactory = (contentNavigator: ContentNavigator) => {
    return <TProps,>(Component: ComponentType<TProps & WithContentNavigator>): ComponentType<TProps> => {
        const ComponentWithContentNavigator = (props: PropsWithChildren<TProps & RouteComponentProps>) => {
            const { children, history } = props;

            contentNavigator.configure({ history });

            return (
                <Component {...props} contentNavigator={contentNavigator}>
                    {children}
                </Component>
            );
        };

        return (isBrowser ? withRouter(ComponentWithContentNavigator) : ComponentWithContentNavigator) as never;
    };
};
