import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { PopulatedImage } from "../../../../common/types/Data/PopulatedImage";
import { BackgroundSize } from "../../../utils/buildOptionalFileBackgroundCSS";
import { PopupImage } from "../Common/PopupImage";

export type Props = BaseComponentProps<PopulatedImage> & {
    size?: BackgroundSize;
};

export const StaticImagePopupModule = (props: PropsWithChildren<Props>) => {
    return <PopupImage {...props} />;
};
