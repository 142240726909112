import React, { ComponentType, PropsWithChildren, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MinimumPopupProps } from "../../hocs/ContentNavigator/MinimumPopupProps";
import { PopupConfig } from "../../hocs/ContentNavigator/PopupConfig";
import { LoadedContent } from "../../../common/types/Data/Content";
import { LinkedContent } from "../../../common/types/Data/LinkedContent";
import { WithActions, withActions } from "../../hocs/withActions";
import { WithModuleDataFetcher, withModuleDataFetcher } from "../../hocs/withModuleDataFetcher";
import { getModuleLoader } from "../../utils/getModuleLoader";
import { BaseComponentProps } from "../../../common/types/Data/BaseComponentProps";
import { WithContentNavigator, withContentNavigator } from "../../hocs/ContentNavigator/withContentNavigator";
import { PopupHandler } from "./PopupHandler";
import { checkUrlId } from "../Utilities/checkUrlId";

type Props = Partial<RouteComponentProps>;

const isBrowser = typeof window !== "undefined";

const PopupRoutes = (props: PropsWithChildren<Props & WithContentNavigator & WithModuleDataFetcher & WithActions>) => {
    const {
        location,
        contentNavigator,
        moduleDataFetcher,
        actions: { setCurrentModuleData },
    } = props;

    const showContent = async <TContent extends LinkedContent, TProps extends BaseComponentProps>(entity: LoadedContent<TContent>, overridingPopupType?: ComponentType<TProps>, popupConfig: PopupConfig = {}) => {
        const moduleLoader = getModuleLoader(entity.type);
        let extractedProperties: TProps | undefined;
        let popupType: ComponentType<MinimumPopupProps> | undefined;
        if (moduleLoader) {
            const { payloadExtractor } = moduleLoader;

            extractedProperties = payloadExtractor(entity, {}) as TProps;
            popupType = moduleLoader.popupModule as ComponentType<MinimumPopupProps>;
        }

        if (popupType && extractedProperties) {
            const { componentType: preferredComponentType, componentProperties: preferredComponentProperties } = contentNavigator.pluckLastContentProperties(entity.id) || { componentType: undefined, componentProperties: undefined };

            const componentType = preferredComponentType || popupType;

            setCurrentModuleData({
                componentType,
                componentProps: {
                    entity: extractedProperties.entity,
                    ...preferredComponentProperties,
                },
                popupParameters: { header: extractedProperties.entity.label, ...popupConfig },
            });
        }
    };

    const onLocationChange = async () => {
        if (location != undefined && location.pathname.length > 1) {
            const contentID = checkUrlId("id");

            if (!contentID) {
                return;
            }

            const archiveContent = contentNavigator.pluckLastContentProperties(contentID);

            if (archiveContent) {
                setCurrentModuleData({
                    componentType: archiveContent.componentType,
                    componentProps: {
                        ...archiveContent.componentProperties,
                    },
                    popupParameters: { header: archiveContent.entity.label, ...archiveContent.popupConfig },
                });

                return;
            }

            try {
                const content = await moduleDataFetcher<LinkedContent>(contentID);
                switch (content.type) {
                    case "quiz":
                        await showContent(content, undefined, { showRelatedContent: false });
                        break;

                    case "interactiveMap":
                        await showContent(content, undefined, { showRelatedContent: false });
                        break;

                    default:
                        await showContent(content);
                        break;
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(`[Routes] Content ${contentID} could not be loaded!: ${error}`);
                // @todo report to sentry
            }
        } else {
            setCurrentModuleData();
        }
    };

    useEffect(() => {
        onLocationChange().then();
    }, [location]);

    return <PopupHandler />;
};

export const PopupRouteHandler = withActions(withModuleDataFetcher(withContentNavigator(isBrowser ? (withRouter(PopupRoutes as never) as never) : PopupRoutes)));
