import React, { PropsWithChildren } from "react";
import { PopulatedContent } from "../../../common/types/Data/Content";

type Props = {
    entity: PopulatedContent;
};

export const RelatedContent = (props: PropsWithChildren<Props>) => {
    void props;
    return <></>;
};
