import React, { PropsWithChildren, useEffect, useRef } from "react";
import { PagePost } from "../../../../common/Facebook/PagePost";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { withActions, WithActions } from "../../../hocs/withActions";

export type Props = BaseComponentProps<PagePost> & {};

const FacebookVideoPopupModuleDecorated = (props: PropsWithChildren<Props & WithActions>) => {
    const { entity } = props;

    // eslint-disable-next-line unicorn/no-null
    const rootRef = useRef(null);

    const videoAttachment = entity.linkedContent.attachments.data.find((candidate) => {
        return candidate.type === "video_inline";
    });

    useEffect(() => {
        if (rootRef && rootRef.current) {
            FB.XFBML.parse(rootRef.current);
        }
    });

    if (videoAttachment) {
        /*
        //Old and bad (but maybe worth fixing later).
        let videoStyle = {
            width: "100%",
            height: "100%",
        };
        const { width, height } = videoAttachment.media.image;
        const maxHeight = 641;

        //1275 * 1700
        console.log("HEIGHT:", height);
        console.log("WIDTH:", width);

        if (width <= height) {
            const videoRatio = width / height;
            const desiredWidth = maxHeight * videoRatio;
            const widthPercent = (desiredWidth / width) * 100;

            console.log("W/H RATIO:", videoRatio);
            console.log("DESIRED WIDTH:", desiredWidth);
            console.log("PERCENT:", widthPercent);

            videoStyle = {
                width: `${widthPercent}%`,
                height: "100%",
            };
        } else {
            const videoRatio = width / height;
            const desiredHeight = maxHeight * videoRatio;
            const heightPercent = (desiredHeight / width) * 100;

            console.log("H/W RATIO:", videoRatio);
            console.log("DESIRED WIDTH:", desiredHeight);
            console.log("PERCENT:", heightPercent);

            videoStyle = {
                width: `${heightPercent + 30}%`,
                height: "100%",
            };
        }
         */

        return (
            <div className={"facebookPopupBase"}>
                <div className={"facebook-video embed-mode"} ref={rootRef}>
                    <div className="fb-video" data-href={videoAttachment.url} data-show-text="false" data-height={"700"}>
                        <blockquote cite="https://developers.facebook.com/facebookapp/videos/10153231379946729/" className="fb-xfbml-parse-ignore">
                            Video Loading...
                        </blockquote>
                    </div>
                </div>
                <div className={"facebookText"}>{entity.label}</div>
            </div>
        );
    }

    return <div className={"facebook-video error"}>Oops, we couldn&rsquo;t find that video!</div>;
};

export const FacebookVideoPopupModule = withActions(FacebookVideoPopupModuleDecorated);
