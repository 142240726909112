import React, { PropsWithChildren, useState } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { ImageGalleryImage, WithImage } from "../../../../common/types/Data/ImageGalleryImage";
import { PopulatedImage } from "../../../../common/types/Data/PopulatedImage";
import { PopulatedImageGallery } from "../../../../common/types/Data/PopulatedImageGallery";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { buildOptionalFileBackgroundCSS } from "../../../utils/buildOptionalFileBackgroundCSS";

type Props = BaseComponentProps<PopulatedImageGallery>;

type PopulatedImageGalleryImage = ImageGalleryImage & WithImage<PopulatedImage>;

const StaticImageGalleryPopupModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics>) => {
    const {
        entity,
        analyticsUtils: { trackContentEvent },
    } = props;
    const { imageGalleryImages } = entity.linkedContent;

    const [selectedImageGalleryImage, setSelectedImageGalleryImage] = useState<PopulatedImageGalleryImage | undefined>();

    const buildImageDetailMarkup = (imageGalleryImage: PopulatedImageGalleryImage) => {
        const index = imageGalleryImages.findIndex((candidate) => {
            return candidate.id === imageGalleryImage.id;
        });
        const previousImageGalleryImage = index > 0 ? imageGalleryImages[index - 1] : imageGalleryImages[imageGalleryImages.length - 1];
        const nextImageGalleryImage = index < imageGalleryImages.length - 1 ? imageGalleryImages[index + 1] : imageGalleryImages[0];

        const style = {
            ...buildOptionalFileBackgroundCSS(imageGalleryImage.image.file, "contain"),
        };
        return (
            <div className={"image-detail"} style={style}>
                <button
                    type={"button"}
                    className={"previous"}
                    onClick={() => {
                        trackContentEvent({ action: "showPreviousImage", value: previousImageGalleryImage.id }, entity);
                        setSelectedImageGalleryImage(previousImageGalleryImage);
                    }}
                >
                    Previous
                </button>
                <button
                    type={"button"}
                    className={"next"}
                    onClick={() => {
                        trackContentEvent({ action: "showNextImage", value: nextImageGalleryImage.id }, entity);
                        setSelectedImageGalleryImage(nextImageGalleryImage);
                    }}
                >
                    Next
                </button>
                <button
                    type={"button"}
                    className={"close"}
                    onClick={() => {
                        // eslint-disable-next-line unicorn/no-useless-undefined
                        setSelectedImageGalleryImage(undefined);
                    }}
                >
                    &times;
                </button>
            </div>
        );
    };

    return (
        <div className={"image-gallery-detail"}>
            <ul>
                {imageGalleryImages.map((imageGalleryImage) => {
                    const style = {
                        ...buildOptionalFileBackgroundCSS(imageGalleryImage.image.thumbnailFile),
                    };

                    return (
                        <li
                            key={imageGalleryImage.id}
                            style={style}
                            onClick={(event) => {
                                event.preventDefault();
                                trackContentEvent({ action: "showImage", value: imageGalleryImage.id }, entity);
                                setSelectedImageGalleryImage(imageGalleryImage);
                            }}
                        />
                    );
                })}
            </ul>
            {selectedImageGalleryImage ? buildImageDetailMarkup(selectedImageGalleryImage) : undefined}
        </div>
    );
};

export const StaticImageGalleryPopupModule = withAnalytics(StaticImageGalleryPopupModuleDecorated);
