import { findMaxIndex } from "./findMaxIndex";
import { GridStructure } from "./GridStructure";
import { GridProperties } from "./GridProperties";

/**
 * Takes a grid structure and the number of modules to render, and returns an object of the properties of the resulting grid.
 *
 * @param {number} moduleCount
 * @param {GridStructure} gridStructure
 *
 * @returns {GridProperties}
 */
export const determineGridProperties = (gridStructure: GridStructure, moduleCount: number): GridProperties => {
    // If there are none, we can bail out now
    if (!moduleCount) {
        return { columnsPerPage: 1, pagesRequired: 1, maxIndex: 0, rowsPerPage: 1, lowestRow: 1 };
    }

    // Determine the maximum index supported by the template
    const maxIndex = findMaxIndex(gridStructure);
    const columnLength = gridStructure[0].length;

    //Calculate how many extra rows we'll need to render, so we don't have blank space.
    const exactPages = maxIndex > 0 ? moduleCount / maxIndex : 0;
    const pagesRequired = Math.ceil(exactPages);
    const fullPages = Math.floor(exactPages);
    const overflowModules = moduleCount - maxIndex * fullPages;

    //Assume that we finish on a full page by default.
    let maxRow = gridStructure.length;
    if (overflowModules > 0) {
        gridStructure.forEach((row, rowIndex) => {
            row.forEach((index) => {
                if (Math.abs(index) <= overflowModules) {
                    maxRow = rowIndex + 1;
                }
            });
        });
    }

    return {
        maxIndex,
        pagesRequired,
        columnsPerPage: columnLength,
        rowsPerPage: gridStructure.length,
        lowestRow: maxRow,
    };
};
