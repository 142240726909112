import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { ErrorMessage } from "../../../../common/types/Data/ErrorMessage";
import { Props, ErrorBaseModule } from "./ErrorBaseModule";

export const moduleLoader: ComponentModuleLoader<ErrorMessage, Props> = {
    baseModule: ErrorBaseModule,
    popupModule: ErrorBaseModule,
    payloadExtractor: (entity) => {
        return {
            entity,
        };
    },
};
