import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { Attachment, PagePost } from "../../../../common/Facebook/PagePost";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { ViewOnFacebook } from "../FacebookCommon/ViewOnFacebook";

export type Props = BaseComponentProps<PagePost> & {};

//@todo: Refactor this to share some code with the static image gallery.
const FacebookGalleryPopupModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics>) => {
    const {
        entity,
        analyticsUtils: { trackContentEvent },
    } = props;

    //If there's no sub images, bail. @todo: Need to see if there's still sub images for posts with only 1 image.
    const { subattachments: subAttachments } = entity.linkedContent.attachments.data[0];
    if (!subAttachments) {
        return <div>Oops, something went wrong!</div>;
    }

    const { data: images } = subAttachments;

    const [selectedImage, setSelectedImage] = useState<Attachment | undefined>();

    // eslint-disable-next-line unicorn/no-null
    const rootRef = useRef(null);

    // Render any XFBML
    useEffect(() => {
        if (rootRef && rootRef.current) {
            FB.XFBML.parse(rootRef.current);
        }
    });

    const BuildImageDetailMarkup = (image: Attachment) => {
        const index = images.findIndex((candidate) => {
            return candidate.target.id == image.target.id;
        });

        const previousImage = index > 0 ? images[index - 1] : images[images.length - 1];
        const nextImage = index < images.length - 1 ? images[index + 1] : images[0];

        const style = {
            backgroundImage: `url('${image.media.image.src}')`,
            backgroundSize: "contain",
        };

        return (
            <div className={"image-detail"} style={style}>
                <button
                    type={"button"}
                    className={"previous"}
                    onClick={() => {
                        trackContentEvent({ action: "showPreviousImage", value: Number.parseInt(image.target.id) }, entity);
                        setSelectedImage(previousImage);
                    }}
                >
                    Previous
                </button>
                <button
                    type={"button"}
                    className={"next"}
                    onClick={() => {
                        trackContentEvent({ action: "showNextImage", value: Number.parseInt(image.target.id) }, entity);
                        setSelectedImage(nextImage);
                    }}
                >
                    Next
                </button>
                <button
                    type={"button"}
                    className={"close"}
                    onClick={() => {
                        // eslint-disable-next-line unicorn/no-useless-undefined
                        setSelectedImage(undefined);
                    }}
                >
                    &times;
                </button>
            </div>
        );
    };

    return (
        <div className={"facebookPopupBase"}>
            <div className={"image-gallery-detail"}>
                <ViewOnFacebook post={entity.linkedContent} />
                <ul>
                    {images.map((image, index) => {
                        const imagesPerRow = 3; //Honestly no idea how/why it's being restricted to 3 per row.
                        const vertGap = 1.5;

                        let width = `${100 / imagesPerRow - vertGap * 2}%`;
                        const height = `${100 / Math.ceil(images.length / imagesPerRow) - vertGap}%`;

                        const finalRowCount = images.length % imagesPerRow;
                        //If the last row isn't full, and we're up to it.
                        if (finalRowCount > 0 && images.length - index < finalRowCount + 1) {
                            let gapSize = (vertGap * imagesPerRow) / finalRowCount;

                            //@Todo: Crunch these into one formula instead of an if.
                            if (finalRowCount == 2) {
                                gapSize = (vertGap * 1.5 * imagesPerRow) / finalRowCount;
                            }

                            width = `${100 / finalRowCount - gapSize}%`;
                        }

                        const style = {
                            backgroundImage: `url('${image.media.image.src}')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: height,
                            width: width,
                        };

                        return (
                            <li
                                key={image.target.id}
                                style={style}
                                onClick={(event) => {
                                    event.preventDefault();
                                    trackContentEvent({ action: "showImage", value: Number.parseInt(image.target.id) }, entity);
                                    setSelectedImage(image);
                                }}
                            />
                        );
                    })}
                </ul>
                {selectedImage ? BuildImageDetailMarkup(selectedImage) : undefined}
            </div>
            <div className={"facebookText"}>{entity.label}</div>
        </div>
    );
};

export const FacebookGalleryPopupModule = withAnalytics(FacebookGalleryPopupModuleDecorated);
