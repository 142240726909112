import { genericShareDescription } from "../../../../common/consts/genericShareDescription";
import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { PopulatedImageGallery } from "../../../../common/types/Data/PopulatedImageGallery";
import { Props, StaticImageGalleryBaseModule } from "./StaticImageGalleryBaseModule";
import { StaticImageGalleryPopupModule } from "./StaticImageGalleryPopupModule";

export const moduleLoader: ComponentModuleLoader<PopulatedImageGallery, Props> = {
    baseModule: StaticImageGalleryBaseModule,
    popupModule: StaticImageGalleryPopupModule,
    payloadExtractor: (entity, config) => {
        const { isLarge } = config;
        return {
            isLarge,
            entity,
        };
    },
    getSocialTags: (entity) => {
        const { linkedContent } = entity;
        const { imageGalleryImages } = linkedContent;
        const thumbnailFile = imageGalleryImages.length > 0 ? imageGalleryImages[0].image.thumbnailFile : undefined;
        return {
            previewImage: thumbnailFile?.path,
            name: entity.label,
            description: genericShareDescription,
        };
    },
};
