import React, { PropsWithChildren } from "react";
import { withActions, WithActions } from "../../hocs/withActions";
import { withAllTagCollections, WithAllTagCollections } from "../../hocs/withAllTagCollections";
import { withState, WithState } from "../../hocs/withState";
import { NoTagButton } from "./NoTagButton";
import { TagButton } from "./TagButton";

type Props = {};

const TagSelectorDecorated = (props: PropsWithChildren<Props & WithState & WithActions & WithAllTagCollections>) => {
    const {
        state: { currentTagCollection },
        allTagCollections,
    } = props;

    return (
        <div className="nav">
            <div className={"tag-parent"}>
                <NoTagButton isActive={currentTagCollection === undefined} />
                {allTagCollections.map((tagCollection) => {
                    return <TagButton tagCollection={tagCollection} isActive={!!currentTagCollection && currentTagCollection.id === tagCollection.id} key={tagCollection.id} />;
                })}
            </div>
        </div>
    );
};

export const TagSelector = withActions(withState(withAllTagCollections(TagSelectorDecorated)));
