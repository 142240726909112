import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { PagePost } from "../../../../common/Facebook/PagePost";
import { FacebookLinkBaseModule } from "./FacebookLinkBaseModule";
import { FacebookLinkPopupModule } from "./FacebookLinkPopupModule";

export const moduleLoader: ComponentModuleLoader<PagePost> = {
    baseModule: FacebookLinkBaseModule,
    popupModule: FacebookLinkPopupModule,
    payloadExtractor: (entity) => {
        return {
            entity,
        };
    },
    getSocialTags: (entity) => {
        return {
            description: entity.linkedContent.message,
            name: entity.linkedContent.message,
            previewImage: undefined,
        };
    },
};
