import pMap from "p-map";
import { PopulatedContent } from "../../../common/types/Data/Content";
import { OrderOptions } from "../../../common/types/Data/OrderOptions";
import { SortOptions } from "../../../common/types/Data/SortOptions";
import { LoadedTagCollection } from "../../../common/types/Data/TagCollection";
import { ModuleFetcher } from "./ModuleFetcher";

export const moduleMultiFetcherFactory = (...moduleFetchers: ModuleFetcher[]): ModuleFetcher => {
    return async (tagCollection: LoadedTagCollection | undefined, sort: SortOptions, order: OrderOptions): Promise<PopulatedContent[]> => {
        const entities = await pMap(moduleFetchers, (moduleFetcher) => {
            return moduleFetcher(tagCollection, sort, order);
        });

        let output = entities.reduce((accumulator, nextEntities) => {
            accumulator.push(...nextEntities);
            return accumulator;
        }, [] as PopulatedContent[]);

        //If there's no collection, or the default collection, don't filter.
        //@Todo: Make this better than a raw string comparison.
        if (tagCollection != undefined && tagCollection.code != "explore") {
            output = output.filter((candidate) => {
                //@Todo: Properly define the type as populated content so it knows that it has contentTags.
                return candidate.contentTags.find((tag) => {
                    return tagCollection?.tagCollectionTags.find((collectionTag) => {
                        return collectionTag.tag.tag_id.toLowerCase() == tag.tag.tag_id.toLowerCase();
                    });
                });
            });
        }

        output = output.sort((a, b) => {
            const dateA = Date.parse(a.createdAt);
            const dateB = Date.parse(b.createdAt);
            //Engagement sort has been removed.
            switch (sort) {
                case "createdAt":
                    return dateA < dateB ? 1 : -1;

                case "createdAtReverse":
                    return dateA > dateB ? 1 : -1;

                default:
                    return 0;
            }
        });

        return output;
    };
};
