import { GridStructure } from "./GridStructure";

/**
 * Takes a completed grid structure and turns it into a template areas string for the CSS.
 *
 * @param {GridStructure} gridStructure
 *
 * @returns {string}
 */
export const buildGridTemplateAreas = (gridStructure: GridStructure): string => {
    return (
        "'" +
        gridStructure
            .map((row) => {
                return row
                    .map((index) => {
                        return `block-${Math.abs(index)}`;
                    })
                    .join(" ");
            })
            .join("' '") +
        "'"
    );
};
