import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { PopulatedDownloadable } from "../../../../common/types/Data/PopulatedDownloadable";
import { generateGenericSocialTags } from "../../../utils/generateGenericSocialTags";
import { StaticDownloadableBaseModule, Props } from "./StaticDownloadableBaseModule";
import { DownloadViewer } from "./DownloadViewer";

export const moduleLoader: ComponentModuleLoader<PopulatedDownloadable, Props> = {
    baseModule: StaticDownloadableBaseModule,
    popupModule: DownloadViewer,
    payloadExtractor: (entity) => {
        return {
            entity,
        };
    },
    getSocialTags: generateGenericSocialTags,
};
