import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { PopulatedImage } from "../../../../common/types/Data/PopulatedImage";
import { addThumbnailURLToList } from "../../../utils/addThumbnailURLToList";
import { Props, StaticImageBaseModule } from "./StaticImageBaseModule";
import { StaticImagePopupModule } from "./StaticImagePopupModule";

export const moduleLoader: ComponentModuleLoader<PopulatedImage, Props> = {
    baseModule: StaticImageBaseModule,
    popupModule: StaticImagePopupModule,
    payloadExtractor: (entity) => {
        return {
            flexSize: 1,
            url: entity.linkedContent.url,
            entity,
        };
    },
    getAssetURIs: (props: Props) => {
        let output: string[] = [];
        const { entity } = props;

        output = addThumbnailURLToList(entity.linkedContent, output);

        return output;
    },
};
