import { WithThumbnailFile } from "../../../../common/types/Data/File";
import { QuizAnswer } from "../../../../common/types/Data/QuizAnswer";
import { QuizResult } from "../../../../common/types/Data/QuizResult";
import { QuizType } from "../../../../common/types/Data/QuizType";

type ResultMap = {
    [key: string]: number;
};

const scoreToResultMap = (score: string | undefined): ResultMap => {
    if (score && score.length > 0 && score[0] === "{") {
        return JSON.parse(score);
    }

    return {};
};

const mapToTuples = <TKey, TValue>(map: Map<TKey, TValue>): [TKey, TValue][] => {
    const values: [TKey, TValue][] = [];

    map.forEach((value, key) => {
        values.push([key, value]);
    });

    return values;
};

const selectPersonalityQuizResult = (quizResults: (QuizResult & WithThumbnailFile)[], selectedQuizAnswers: QuizAnswer[]): QuizResult & WithThumbnailFile => {
    const quizResultScores = selectedQuizAnswers.reduce((quizResultScores, quizAnswer) => {
        const resultMap = scoreToResultMap(quizAnswer.score);
        for (const property in resultMap) {
            if (Object.prototype.hasOwnProperty.call(resultMap, property)) {
                const resultId = Number.parseInt(property, 10);
                const currentResultScore = quizResultScores.get(resultId) || 0;
                quizResultScores.set(resultId, currentResultScore + resultMap[property]);
            }
        }
        return quizResultScores;
    }, new Map<number, number>());

    // Find the result with the highest score
    const highestTuple = mapToTuples(quizResultScores).reduce(
        (currentHigh, nextCandidate) => {
            return currentHigh[1] > nextCandidate[1] ? currentHigh : nextCandidate;
        },
        [0, 0],
    );

    return (
        quizResults.find((quizResult) => {
            return quizResult.id === highestTuple[0];
        }) || quizResults[0]
    );
};

const selectChallengeQuizResult = (quizResults: (QuizResult & WithThumbnailFile)[], selectedQuizAnswers: QuizAnswer[]): QuizResult & WithThumbnailFile => {
    // Sort the results by threshold, highest to lowest
    const sortedQuizResults = quizResults.sort((a, b) => {
        return (b.scoreThreshold || 0) - (a.scoreThreshold || 0);
    });

    // Get the sum score from the results
    const score = selectedQuizAnswers.reduce((accumulated, quizAnswer) => {
        return accumulated + Number.parseInt(quizAnswer.score || "0", 10);
    }, 0);

    for (const quizResult of sortedQuizResults) {
        if (quizResult.scoreThreshold && quizResult.scoreThreshold <= score) {
            return quizResult;
        }
    }

    // No good, just return the lowest one
    return sortedQuizResults[sortedQuizResults.length - 1];
};

export const selectQuizResult = (quizType: QuizType | undefined, quizResults: (QuizResult & WithThumbnailFile)[], selectedQuizAnswers: QuizAnswer[]): QuizResult & WithThumbnailFile => {
    switch (quizType) {
        case QuizType.Personality:
            return selectPersonalityQuizResult(quizResults, selectedQuizAnswers);
        case QuizType.Challenge:
        case QuizType.ChallengeReveal:
        case QuizType.Slider:
            return selectChallengeQuizResult(quizResults, selectedQuizAnswers);
        default:
            throw new Error(`Unknown Quiz Type ${quizType}`);
    }
};
