import React, { PropsWithChildren } from "react";
import { PagePost } from "../../../../common/Facebook/PagePost";

interface IProps {
    post: PagePost;
}

export const ViewOnFacebook = (props: PropsWithChildren<IProps>) => {
    const { post } = props;

    const OnClick = () => {
        window.open(post.permalink_url);
    };

    return (
        <div className={"viewonfacebook"} onClick={OnClick}>
            <div className={"facebookLogo"} />
            View on Facebook
        </div>
    );
};
