import { API } from "../../../common/types/API/API";
import { APIRequestParameters } from "../../../common/types/API/APIRequestParameters";
import { APIEntitiesPayload } from "../../../common/types/Data/APIEntitiesPayload";
import { PopulatedContent } from "../../../common/types/Data/Content";
import { OrderOptions } from "../../../common/types/Data/OrderOptions";
import { SortOptions } from "../../../common/types/Data/SortOptions";
import { LoadedTagCollection } from "../../../common/types/Data/TagCollection";
import { ModuleFetcher } from "./ModuleFetcher";

export const moduleNetlifyFetcherFactory = (api: API): ModuleFetcher => {
    return async (tagCollection: LoadedTagCollection | undefined, sort: SortOptions, order: OrderOptions): Promise<PopulatedContent[]> => {
        const parameters: APIRequestParameters = { sort, order };

        if (tagCollection) {
            parameters.tags = tagCollection.tagCollectionTags
                .map((tagCollectionTag) => {
                    return `${tagCollectionTag.tag.id}`;
                })
                .join(",");
        }

        const [facebookResponse, youtubeResponse] = await Promise.all(
            ["facebookModules", "youtubeModules"].map((endpoint) => {
                return api.get<APIEntitiesPayload<PopulatedContent>>(endpoint, parameters);
            }),
        );

        const entitiesUnsorted = [...facebookResponse.entities, ...youtubeResponse.entities];

        // @todo Sort by date
        const entities = entitiesUnsorted.sort((a, b) => {
            return b.priority - a.priority;
        });

        return entities;
    };
};
