import React, { PropsWithChildren } from "react";
import { LoadedContent } from "../../../common/types/Data/Content";
import { WithState, withState } from "../../hocs/withState";
import { PagePost } from "../../../common/Facebook/PagePost";
import { ComponentPanel } from "../Modules/Common/ComponentPanel";
import { YoutubeSearchResult } from "../../../common/Youtube/YoutubeSearchResult";

type Props = {
    entity: LoadedContent | undefined;
    waitForInteraction?: boolean;
    showPanel: boolean;
};

const EngagementDecorated = (props: PropsWithChildren<Props & WithState>) => {
    const { entity, children, showPanel } = props;
    let engagementNumber = 0;

    if (entity) {
        if (entity.type.startsWith("facebook")) {
            const content = entity.linkedContent as PagePost;
            engagementNumber = content.total_likes;
        } else if (entity.type.startsWith("youtube")) {
            const content = entity.linkedContent as YoutubeSearchResult;
            engagementNumber = content.statistics.viewCount;
        }
    }

    return (
        <div className={"engagement"} data-entity-id={entity?.id}>
            {children}
            {showPanel && entity ? <ComponentPanel type={entity.type} engagement={engagementNumber} watched={""} /> : undefined}
        </div>
    );
};

export const Engagement = withState(EngagementDecorated);
