import React, { PropsWithChildren } from "react";
import { TagButton } from "./TagButton";

type Props = {
    isActive: boolean;
};

const NoTagButtonDecorated = (props: PropsWithChildren<Props>) => {
    const { isActive } = props;

    return (
        <TagButton
            tagCollection={{
                id: 0,
                code: "explore",
                label: "Explore",
                createdAt: "",
                updatedAt: "",
                tagCollectionTags: [],
            }}
            isActive={isActive}
        />
    );
};

export const NoTagButton = NoTagButtonDecorated;
