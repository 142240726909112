import { Action } from "redux";
import { ReduxActionType } from "../../../common/types/ReduxActionType";
import { CurrentModuleData } from "../../../common/types/State";
import { actionReducer } from "../actionReducer";
import { initialState } from "../initialState";

export type SetCurrentModuleDataAction = {
    type: ReduxActionType.SetCurrentModuleData;
    currentModuleData: CurrentModuleData | undefined;
};

export const createSetCurrentModuleDataAction = (currentModuleData?: CurrentModuleData): SetCurrentModuleDataAction => {
    return {
        type: ReduxActionType.SetCurrentModuleData,
        currentModuleData,
    };
};

export const isSetCurrentModuleDataAction = (action: Action): action is SetCurrentModuleDataAction => {
    return action.type === ReduxActionType.SetCurrentModuleData;
};

export const setCurrentModuleDataReducer = actionReducer(
    initialState,
    (previousState = initialState, action) => {
        const { currentModuleData } = action;

        return {
            ...previousState,
            currentModuleData,
        };
    },
    isSetCurrentModuleDataAction,
);
