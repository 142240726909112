import { ComponentLoadState } from "../../common/types/Data/ComponentLoadState";

/**
 * Returns whether the loader should be visible, based on the current component load state.
 *
 * @param {ComponentLoadState} loadState
 *
 * @returns {boolean}
 */
export const shouldShowComponentModuleLoader = (loadState: ComponentLoadState): boolean => {
    return loadState != ComponentLoadState.Loaded;
};
