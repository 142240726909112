import { Config } from "../types/Config";

export const config: Config = {
    fbAppId: `${process.env.FB_APP_ID}`,
    fbAppSecret: `${process.env.FB_APP_SECRET}`,
    fbLoginCode: `${process.env.FB_LOGIN_CODE}`,
    fbShortLivedAccessToken: `${process.env.FB_SHORT_LIVED_ACCESS_TOKEN}`,
    fbLongLivedAccessToken: `${process.env.FB_LONG_LIVED_ACCESS_TOKEN}`,
    fbPageAccessToken: `${process.env.FB_PAGE_ACCESS_TOKEN}`,
    fbPageId: `${process.env.FB_PAGE_ID}`,
    fbUserId: `${process.env.FB_USER_ID}`,
    s3CloudfrontUrl: `${process.env.GATSBY_S3_CLOUDFRONT_URL}`,
    s3FileCacheMaxAge: Number.parseInt(`${process.env.GATSBY_S3_CLOUDFRONT_URL || 3600}`, 10),
    googleAppClientEmail: `${process.env.GOOGLE_APP_CLIENT_EMAIL}`,
    googleAppPrivateKeyBase64: `${process.env.GOOGLE_APP_PRIVATE_KEY_B64}`,
    enableFunctionFileCache: !!`${process.env.ENABLE_FUNCTION_FILE_CACHE}`,
    s3Bucket: `${process.env.S3_BUCKET}`,
    s3Region: `${process.env.S3_REGION}`,
    awsIamAccessKeyId: `${process.env.HUB_AWS_IAM_ACCESS_KEY_ID}`,
    awsIamSecretKey: `${process.env.HUB_AWS_IAM_SECRET_KEY}`,
    blacklistedIds: `${process.env.BLACKLISTED_IDS}`.split(","),
};
