import React, { PropsWithChildren } from "react";
import { WithThumbnailFile } from "../../../../common/types/Data/File";
import { QuizQuestion } from "../../../../common/types/Data/QuizQuestion";
import { StandardImageBox } from "../Common/StandardImageBox";

interface IProps {
    quizQuestion: QuizQuestion & WithThumbnailFile;
    revealed: boolean;
}

export const QuizQuestionImage = (props: PropsWithChildren<IProps>) => {
    const { quizQuestion, revealed } = props;

    const { thumbnailFile } = quizQuestion;

    if (thumbnailFile) {
        const cover = (
            <div className={"cover"}>
                <span>?</span>
            </div>
        );
        return (
            <StandardImageBox file={thumbnailFile} classNames={["quiz-question-image"]} backgroundSize={"contain"}>
                {revealed ? undefined : cover}
            </StandardImageBox>
        );
    } else {
        return <></>;
    }
};
