import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { PopulatedDownloadable } from "../../../../common/types/Data/PopulatedDownloadable";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ModulePreviewImage } from "../Common/ModulePreviewImage";

export type Props = BaseComponentProps<PopulatedDownloadable>;

const buildHrefAndPopup = (downloadable: PopulatedDownloadable) => {
    let shouldPopup = true;
    let href: string | undefined;
    if (downloadable.url.length > 0) {
        href = downloadable.url;
        shouldPopup = false;
    }

    return { shouldPopup, href };
};

const StaticDownloadableBaseModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics & WithContentNavigator>) => {
    const {
        entity,
        contentNavigator,
        nested,
        analyticsUtils: { trackClickModuleEvent },
    } = props;
    const { href, shouldPopup } = buildHrefAndPopup(entity.linkedContent);

    const onClick = async () => {
        trackClickModuleEvent(entity);
        if (shouldPopup) {
            contentNavigator.open("downloadable", { entity, size: undefined }, { header: entity.label, nested });
        }
    };

    return (
        <ModulePreview classNames={["download"]} downloadable={!!href} onClick={onClick}>
            <ModulePreviewHeader label={entity.label} />
            <ModulePreviewImage entity={entity} file={entity.linkedContent.thumbnailFile} href={href} />
        </ModulePreview>
    );
};

export const StaticDownloadableBaseModule = withAnalytics(withContentNavigator(StaticDownloadableBaseModuleDecorated));
