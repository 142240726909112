import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { PopulatedLocationProfile } from "../../../../common/types/Data/PopulatedLocationProfile";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ModulePreviewImage } from "../Common/ModulePreviewImage";

export type Props = BaseComponentProps<PopulatedLocationProfile>;

const StaticLocationProfileBaseModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics & WithContentNavigator>) => {
    const {
        entity,
        contentNavigator,
        nested,
        analyticsUtils: { trackClickModuleEvent },
    } = props;

    const onClick = () => {
        trackClickModuleEvent(entity);
        contentNavigator.open("locProfile", { entity }, { header: entity.linkedContent.label, nested });
    };

    return (
        <ModulePreview classNames={["location-profile info"]} onClick={onClick}>
            <ModulePreviewHeader label={entity.label} />
            <ModulePreviewImage file={entity.linkedContent.thumbnailFile} entity={entity} />
        </ModulePreview>
    );
};

export const StaticLocationProfileBaseModule = withAnalytics(withContentNavigator(StaticLocationProfileBaseModuleDecorated));
