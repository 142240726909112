import React, { PropsWithChildren, useEffect, useState } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { PopulatedLocationProfile } from "../../../../common/types/Data/PopulatedLocationProfile";
import { WithContentNavigator, withContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { ComponentModule } from "../../Grid/ComponentModule";
import { StaticLocationProfileCore } from "./StaticLocationProfileCore";

type Props = BaseComponentProps<PopulatedLocationProfile>;

const StaticLocationProfilePopupModuleDecorated = (props: PropsWithChildren<Props & WithAnalytics & WithContentNavigator>) => {
    const {
        entity,
        analyticsUtils: { trackContentEvent },
        contentNavigator,
    } = props;

    const [currentContentId, setCurrentContentId] = useState<number | string>(entity.id);
    const [subModules, setSubModules] = useState<(number | string)[]>([]);

    useEffect(() => {
        if (entity.linkedContent.locationProfileContent.length > 0) {
            trackContentEvent({ action: "showLocationProfileContent", value: entity.linkedContent.locationProfileContent[0].contentId }, entity);
            setCurrentContentId(entity.id);

            const tempModules: (number | string)[] = [];
            tempModules.push(entity.id);
            entity.linkedContent.locationProfileContent.map((content) => {
                tempModules.push(content.contentId);
            });

            setSubModules(tempModules);
        }
    }, [entity]);

    // Control the navigation inside this component
    useEffect(() => {
        return contentNavigator.overrideGotoId((nextId) => {
            trackContentEvent({ action: "showLocationProfileContent", label: `${nextId}` }, entity);
            setCurrentContentId(nextId);
        });
    }, []);

    return (
        <div className={"locationViewer"}>
            <div className={"locationContent"}>{currentContentId === entity.id ? <StaticLocationProfileCore entity={entity} /> : <ComponentModule big={true} useDetailModule={true} contentId={`${currentContentId}`} isSubModule={true} nested={true} />}</div>
            <div className={`locationSubOptions ${entity.type}-sub-options`}>
                <div className="triangle" />
                {subModules.map((contentId, index) => {
                    const extraClasses: string[] = [];

                    if (contentId == currentContentId) {
                        extraClasses.push("activeSubmodule");
                    }

                    return <ComponentModule key={index} big={false} contentId={`${contentId}`} extraClasses={extraClasses} staggerIndex={index} isSubModule={true} nested={true} />;
                })}
            </div>
        </div>
    );
};

export const StaticLocationProfilePopupModule = withAnalytics(withContentNavigator(StaticLocationProfilePopupModuleDecorated));
