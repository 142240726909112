import React, { PropsWithChildren, useEffect } from "react";
import { PopulatedContent } from "../../../../common/types/Data/Content";
import { PopulatedLocationProfile } from "../../../../common/types/Data/PopulatedLocationProfile";
import { withContentNavigator, WithContentNavigator } from "../../../hocs/ContentNavigator/withContentNavigator";
import { withAnalytics, WithAnalytics } from "../../../hocs/withAnalytics";
import { InteractiveMapMountData, WithMapHooks, withMapHooks } from "../../../hocs/withMapHooks";
import { withModuleDataFetcher, WithModuleDataFetcher } from "../../../hocs/withModuleDataFetcher";

type Props = {
    entity: PopulatedContent;
};

const InteractiveMapDecorated = (props: PropsWithChildren<Props & WithAnalytics & WithContentNavigator & WithModuleDataFetcher & WithMapHooks>) => {
    const { entity, analytics, contentNavigator, moduleDataFetcherByType, mapHooks } = props;
    const canvasReference = React.createRef<HTMLDivElement>();

    const buildEventDetails = async (): Promise<InteractiveMapMountData | undefined> => {
        const target = canvasReference.current;
        if (!target) {
            return;
        }

        const entities = await moduleDataFetcherByType<PopulatedLocationProfile>("locProfile");

        return {
            target,
            id: entity.id,
            onClickCallback: (clickedEntity) => {
                analytics.track("markerClick", { category: "InteractiveMap", label: clickedEntity.label, value: clickedEntity.id });
                contentNavigator.open("locProfile", { entity: clickedEntity }, { header: clickedEntity.label });
            },
            entities,
        };
    };

    const mountMap = async () => {
        const detail = await buildEventDetails();
        if (detail) {
            mapHooks.mount(detail);
        }
    };

    const unmountMap = () => {
        const { id } = entity;
        const target = canvasReference.current;
        if (target) {
            mapHooks.unmount({ target, id });
        }
    };

    useEffect(() => {
        unmountMap();
        mountMap().then();

        return () => {
            unmountMap();
        };
    }, []);

    const style = { width: "100%", height: "100%" };
    return (
        <>
            <div className={"mapTitle"}>
                <h2>INTERACTIVE MAP OF SANCTUARY CITY</h2>
                <p>Pan around to explore!</p>
            </div>
            <div style={style} ref={canvasReference} className={"modulecontent"} />
        </>
    );
};

export const InteractiveMap = withMapHooks(withModuleDataFetcher(withAnalytics(withContentNavigator(InteractiveMapDecorated))));
