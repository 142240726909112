import React, { PropsWithChildren } from "react";
import { buildOptionalFileBackgroundCSS } from "../../../utils/buildOptionalFileBackgroundCSS";
import { ModulePreview } from "../Common/ModulePreview";
import { ModulePreviewHeader } from "../Common/ModulePreviewHeader";
import { ModulePreviewImage } from "../Common/ModulePreviewImage";
import { Props } from "./StaticDownloadableBaseModule";

export const DownloadViewer = (props: PropsWithChildren<Props>) => {
    const { entity } = props;

    let href = "";
    if (entity.linkedContent.url.length > 0) {
        href = entity.linkedContent.url;
    } else if (entity.linkedContent.file) {
        href = entity.linkedContent.file.path;
    }

    const style = {
        ...buildOptionalFileBackgroundCSS(entity.linkedContent.thumbnailFile),
        backgroundSize: "contain",
    };

    return (
        <ModulePreview classNames={["download-viewer"]} downloadable={true} href={href}>
            <ModulePreviewHeader label={entity.label} />
            <ModulePreviewImage entity={entity} file={entity.linkedContent.thumbnailFile} style={style} />
        </ModulePreview>
    );
};
