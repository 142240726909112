import { File } from "../../common/types/Data/File";

export type BackgroundSize = "cover" | "contain";

type CSSProperties = {
    backgroundImage?: string;
    backgroundSize?: BackgroundSize;
};

export const buildOptionalFileBackgroundCSS = (file?: File, backgroundSize: BackgroundSize = "cover"): CSSProperties => {
    if (file) {
        return {
            backgroundImage: `url('${file.path}')`,
            backgroundSize,
        };
    }

    return {};
};
