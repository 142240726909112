import React, { PropsWithChildren } from "react";
import { LoadedTagCollection, TagCollection } from "../../../common/types/Data/TagCollection";
import { withActions, WithActions } from "../../hocs/withActions";

type Props = {
    tagCollection: LoadedTagCollection;
    isActive: boolean;
};

const deriveImageName = (tagCollection: TagCollection): string => {
    let imageName = "exploreicon";

    if (tagCollection.code === "play") {
        imageName = "playicon";
    } else if (tagCollection.code === "read") {
        imageName = "readicon";
    } else if (tagCollection.code === "download") {
        imageName = "downloadicon";
    }

    return imageName;
};

const TagButtonDecorated = (props: PropsWithChildren<Props & WithActions>) => {
    const {
        tagCollection,
        actions: { setCurrentTagCollection },
        isActive,
    } = props;

    const classNames = ["tag-option", `${tagCollection.code}`];

    if (isActive) {
        classNames.push("activeTag");
    }

    return (
        <div
            className={classNames.join(" ")}
            onClick={() => {
                setCurrentTagCollection(tagCollection);
            }}
        >
            <div className={`tagimage noselect ${deriveImageName(tagCollection)}`} />
            <div className={`tagname noselect ${tagCollection.code}`}>{tagCollection.label}</div>
        </div>
    );
};

export const TagButton = withActions(TagButtonDecorated);
