import React, { ComponentType, PropsWithChildren, useEffect, useState } from "react";
import { LoadedTagCollection } from "../../common/types/Data/TagCollection";
import allTagCollectionsData from "../data/all-tag-collections.json";

export type WithAllTagCollections = {
    allTagCollections: LoadedTagCollection[];
};

type AllTagCollectionsData = {
    entities: LoadedTagCollection[];
};

export const withAllTagCollections = <TProps,>(Component: ComponentType<TProps & WithAllTagCollections>): ComponentType<TProps> => {
    const WithAllTagCollections: ComponentType<TProps> = (props: PropsWithChildren<TProps>) => {
        const [allTagCollections, setAllTagCollections] = useState<LoadedTagCollection[]>([]);

        useEffect(() => {
            // No need to load from backend any more
            setAllTagCollections((allTagCollectionsData as AllTagCollectionsData).entities);
        }, []);

        return <Component {...props} allTagCollections={allTagCollections} />;
    };

    return WithAllTagCollections;
};
