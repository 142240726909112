import React, { PropsWithChildren } from "react";
import { PopulatedContent } from "../../../common/types/Data/Content";
import { RelatedContent } from "./RelatedContent";
import { FacebookShareButton, FacebookShareCount } from "react-share";

interface IProps {
    classNames: string[];
    shortHeader?: string;
    header: string;
    showRelatedContent: boolean;
    entity: PopulatedContent;
    onClose: (pushHistory: boolean) => void;
}

export const PopupBody = (props: PropsWithChildren<IProps>) => {
    const { classNames, shortHeader, header, children, showRelatedContent, entity, onClose } = props;
    const deepLinkUrl = `https://${document.URL.split("/")[2]}/content?id=${entity.id}`;

    const closePopup = () => {
        onClose(true);
    };

    return (
        <div id={"popup"} className={classNames.join(" ")} onClick={closePopup}>
            <div
                className={"popup-content"}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className={"popupViewer"}>
                    <div className={"ComponentRow"}>
                        <div className={"popupHeader"}>
                            <div className={"facebookShare"}>
                                {deepLinkUrl != "" ? (
                                    <>
                                        <div className="facebookLogo" />
                                        <FacebookShareButton url={deepLinkUrl}>Share</FacebookShareButton>
                                        <FacebookShareCount url={deepLinkUrl} />
                                    </>
                                ) : undefined}
                            </div>
                            <div className={"popupLogo"} />
                            <h2>{shortHeader || header}</h2>
                            <div className={"popupClose"} onClick={closePopup} />
                        </div>
                        <div className={"popupDetails"}>
                            <div className={"ComponentModule big"}>{children}</div>
                        </div>
                        {showRelatedContent ? <RelatedContent entity={entity} /> : undefined}
                    </div>
                </div>
            </div>
        </div>
    );
};
