import { GridStructure } from "./GridStructure";
import { GridProperties } from "./GridProperties";

/**
 * Takes a grid structure and produces a version that repeats itself enough to fill the required number of pages.
 *
 * @param {GridStructure} gridStructure
 * @param {GridProperties} gridProperties
 * @param {boolean} useHorizontal
 *
 * @returns {GridStructure}
 */
export const expandGridStructure = (gridStructure: GridStructure, gridProperties: GridProperties, useHorizontal: boolean): GridStructure => {
    const expandedGridStructure = [...gridStructure];
    const { pagesRequired, columnsPerPage, maxIndex } = gridProperties;

    if (useHorizontal) {
        for (let loop = 1; loop < pagesRequired; loop++) {
            expandedGridStructure.forEach((row) => {
                row.push(
                    ...row.slice(0, columnsPerPage).map((baseIndex) => {
                        return Math.abs(baseIndex) + loop * maxIndex;
                    }),
                );
            });
        }
    } else {
        const cloneOriginalStructure: number[][] = ([] as number[][]).concat(expandedGridStructure);
        for (let loop = 1; loop < pagesRequired; loop++) {
            expandedGridStructure.push(
                ...cloneOriginalStructure.map((row) => {
                    return row.map((baseIndex) => {
                        return Math.abs(baseIndex) + loop * maxIndex;
                    });
                }),
            );
        }
    }

    return expandedGridStructure;
};
