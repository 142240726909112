import React, { PropsWithChildren, useEffect } from "react";
import { WithAnalytics, withAnalytics } from "../hocs/withAnalytics";
import { SEO } from "./SEO";

// Import either the regular non-persisted store, or the persisted one
import { PersistedStore as Store } from "./PersistedStore";

type Props = {};

const LayoutDecorated = (props: PropsWithChildren<Props & WithAnalytics>) => {
    const { children, analytics } = props;

    useEffect(() => {
        analytics.page().then();
    }, []);

    return (
        <Store>
            <SEO title={"Home"} />
            {children}
            <div id="fb-root" />
        </Store>
    );
};

export const Layout = withAnalytics(LayoutDecorated);
