import React, { ComponentType, PropsWithChildren } from "react";
import { ModuleFetcher } from "./Modules/ModuleFetcher";
import { moduleMultiFetcherFactory } from "./Modules/moduleMultiFetcherFactory";
import { moduleNetlifyFetcherFactory } from "./Modules/moduleNetlifyFetcherFactory";
import { moduleStaticFetcherFactory } from "./Modules/moduleStaticFetcherFactory";
import { WithAPI, withAPI } from "./withAPI";

export type WithModuleFetcher = {
    moduleFetcher: ModuleFetcher;
};

const withModuleFetcherFactory = () => {
    return <TProps,>(Component: ComponentType<TProps & WithModuleFetcher>): ComponentType<TProps> => {
        const ComponentWithModuleFetcher = (props: PropsWithChildren<TProps & WithAPI>) => {
            const { api } = props;

            const netlifyFetcher = moduleNetlifyFetcherFactory(api);
            const staticFetcher = moduleStaticFetcherFactory();

            const moduleFetcher = moduleMultiFetcherFactory(netlifyFetcher, staticFetcher);

            return <Component {...props} moduleFetcher={moduleFetcher} />;
        };

        return withAPI(ComponentWithModuleFetcher);
    };
};

export const withModuleFetcher = withModuleFetcherFactory();
