import googleAnalytics from "@analytics/google-analytics";
import Analytics, { AnalyticsInstance } from "analytics";
import React, { ComponentType } from "react";
import { PopulatedContent } from "../../common/types/Data/Content";

export type WithAnalytics = {
    analytics: AnalyticsInstance;
    analyticsUtils: {
        trackContentEvent: (properties: Partial<AnalyticsEventProperties> & Pick<AnalyticsEventProperties, "action">, entity: PopulatedContent) => void;
        trackClickModuleEvent: (entity: PopulatedContent) => void;
    };
};

type AnalyticsEventProperties = {
    category: string;
    action: string;
    label: string;
    value?: number;
};

const withAnalyticsFactory = () => {
    const analytics = Analytics({
        app: "steps-tracker",
        plugins: [
            googleAnalytics({
                trackingId: `${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`,
            }),
        ],
    });

    const trackContentEvent = (properties: Partial<AnalyticsEventProperties> & Pick<AnalyticsEventProperties, "action">, entity: PopulatedContent) => {
        const { label, value, category, action } = {
            label: entity.id,
            category: entity.type,
            ...properties,
        };

        analytics.track(action, {
            category,
            label,
            value,
        });
    };

    const trackClickModuleEvent = (entity: PopulatedContent) => {
        return trackContentEvent({ action: "clickModule" }, entity);
    };

    const analyticsUtils = {
        trackContentEvent,
        trackClickModuleEvent,
    };

    return <TProps,>(Component: ComponentType<TProps & WithAnalytics>): ComponentType<TProps> => {
        const WithAnalytics = (props: TProps) => {
            return <Component {...props} analytics={analytics} analyticsUtils={analyticsUtils} />;
        };

        return WithAnalytics;
    };
};

export const withAnalytics = withAnalyticsFactory();
