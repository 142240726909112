import { CSSProperties } from "react";

/**
 * Build the CSS styling for a component module.
 *
 * @param {string} gridSlot
 * @param {number} stagger
 *
 * @returns {CSSProperties}
 */
export const buildComponentModuleStyle = (gridSlot?: string, stagger?: number): CSSProperties => {
    const props: CSSProperties = {};
    const animationDelayStep = 50;

    if (gridSlot) {
        props.gridArea = gridSlot;

        const index = Number.parseInt(gridSlot.split("-")[1]);
        props.animationDelay = index * animationDelayStep + "ms";
        // props.display = "block";
        // props.flex = "";
        // props.width = "100%";
        // props.height = "100%";
    } else if (stagger) {
        props.animationDelay = (stagger * animationDelayStep).toString() + "ms";
    }

    return props;
};
