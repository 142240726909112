import React, { PropsWithChildren, useEffect, useState } from "react";
import { contentIsLoadedContent } from "../../../common/Content/contentIsLoadedContent";
import { arrangeContent } from "../../../common/Grid/arrangeContent";
import { buildGridStyle } from "../../../common/Grid/buildGridStyle";
import { deriveBigModulePattern } from "../../../common/Grid/deriveBigModulePattern";
import { deriveGridStructure } from "../../../common/Grid/deriveGridStructure";
import { determineGridProperties } from "../../../common/Grid/determineGridProperties";
import { Content } from "../../../common/types/Data/Content";
import { WithGridLayout } from "../../hocs/withGridLayout";
import { WithModuleFetcher } from "../../hocs/withModuleFetcher";
import { WithState } from "../../hocs/withState";
import { BannerParent } from "../Banner/BannerParent";
import { CreditsMobile } from "../Credits/CreditsMobile";
import { ResponsiveStateListener } from "../Utilities/ResponsiveStateListener";
import { ComponentModule } from "./ComponentModule";
import { Loader } from "./Loader";

export type Props = {
    useHorizontal?: boolean;
};

// @todo do this via CSS/State
const setCursor = (cursor: "wait" | "default") => {
    document.body.style.cursor = cursor;
};

const desktopBreakpointWidth = "1600px";
const smallDesktopBreakpointWidth = "1025px";
const tabletBreakpointWidth = "768px";

export const ComponentGridDecorated = (props: PropsWithChildren<Props & WithState & WithGridLayout & WithModuleFetcher>) => {
    const {
        gridLayout,
        useHorizontal,
        moduleFetcher,
        state: { currentSort, currentOrder, currentTagCollection, previousTagCollection },
    } = props;

    // const [isFetchingModuleIDs, setIsFetchingModuleIDs] = useState<boolean>(false);
    const [content, setContent] = useState<Content[]>([]);
    const [isTabletOrWider, setIsTabletOrWider] = useState(false);
    const [isDesktopOrWider, setIsDesktopOrWider] = useState(false);
    const [isSmallDesktopOrWider, setIsSmallDesktopOrWider] = useState(false);
    const [forceRender, setForceRender] = useState(false);
    const [pageNum, setPageNum] = useState(1);

    const stateTest = () => {
        setForceRender(!forceRender);
    };

    const loadModules = async () => {
        setContent([]);
        setCursor("wait");
        const newContent = await moduleFetcher(currentTagCollection, currentSort, currentOrder);
        setCursor("default");
        setContent(newContent);
    };

    useEffect(() => {
        window.addEventListener("resize", stateTest);
        return () => {
            window.removeEventListener("resize", stateTest);
        };
    }, []);

    useEffect(() => {
        loadModules().then();

        if (currentTagCollection != previousTagCollection) {
            setPageNum(1);
        }
    }, [currentTagCollection, currentSort, currentOrder]);

    const gridStructure = deriveGridStructure(isSmallDesktopOrWider ? gridLayout.desktopGridTemplate : gridLayout.mobileGridTemplate);
    const bigModules = deriveBigModulePattern(gridStructure);
    const orderedContent = arrangeContent(content, bigModules);
    const gridProperties = determineGridProperties(gridStructure, orderedContent.length);
    const classes = ["gridHolder"];
    if (useHorizontal) {
        classes.push("horizontal");
    } else {
        classes.push("vertical");
    }
    if (isDesktopOrWider) {
        classes.push("size-desktop");
    } else if (isTabletOrWider) {
        classes.push("size-tablet");
    } else {
        classes.push("size-mobile");
    }

    const buildKey = (content: Content) => {
        return [content.id, currentTagCollection ? currentTagCollection.id : "", currentSort.toString(), currentOrder.toString()].join(".");
    };

    const checkScrollHeight = () => {
        const grid = document.querySelector(".gridHolder");
        if (grid && pageNum * gridProperties.maxIndex < orderedContent.length) {
            const distanceToEnd = grid.scrollHeight - grid.scrollTop;

            const pageTarget = window != undefined ? window.innerHeight : 0;

            if (distanceToEnd <= pageTarget) {
                setPageNum(pageNum + 1);
            }
        }
    };

    const renderGrids = () => {
        const grids = [];
        for (let i = 0; i < pageNum; ++i) {
            const startNumber = Math.min(Math.max(i * gridProperties.maxIndex, 0), orderedContent.length);
            const endNumber = Math.min(Math.max((i + 1) * gridProperties.maxIndex, 1), orderedContent.length);
            const contentList = orderedContent.slice(startNumber, endNumber);

            let lowestRow = gridProperties.lowestRow;
            if (endNumber % gridProperties.maxIndex === 0) {
                lowestRow = gridProperties.rowsPerPage;
            }

            grids.push(
                <div id={"componentgrid"} className={`ComponentParent component-grid`} key={i} style={buildGridStyle(gridStructure, { ...gridProperties, lowestRow }, !!useHorizontal, isSmallDesktopOrWider || isDesktopOrWider)}>
                    {contentList.map((content, index) => {
                        const entity = contentIsLoadedContent(content) ? content : undefined;
                        const gridSlot = `block-${index + 1}`;
                        const isLarge = bigModules.includes(index);
                        return <ComponentModule key={buildKey(content)} entity={entity} big={false} contentId={`${content.id}`} showPanel={true} gridSlot={gridSlot} isLarge={isLarge} />;
                    })}
                </div>,
            );

            //Once we're out of content, show the credits.
            if (endNumber === orderedContent.length && orderedContent.length > 0) {
                grids.push(<CreditsMobile key={"credits"} />);
                break;
            }
        }
        return grids;
    };

    return (
        <div className={classes.join(" ")} onScroll={checkScrollHeight}>
            <ResponsiveStateListener
                queries={{
                    isTabletOrWider: `(min-width: ${tabletBreakpointWidth})`,
                    isSmallDesktopOrWider: `(min-width: ${smallDesktopBreakpointWidth})`,
                    isDesktopOrWider: `(min-width: ${desktopBreakpointWidth})`,
                    forceRender: `(max-height: 0)`,
                }}
                onChange={(newState) => {
                    setIsTabletOrWider(newState.isTabletOrWider);
                    setIsSmallDesktopOrWider(newState.isSmallDesktopOrWider);
                    setIsDesktopOrWider(newState.isDesktopOrWider);
                    setForceRender(newState.forceRender);
                }}
            />
            <div className={"component-grid-spacer"}>
                <BannerParent />
                {content.length === 0 ? <Loader show={content.length === 0} imageName={"tfsc-preloader-wood"} /> : renderGrids()}
                {pageNum == 1 ? <CreditsMobile /> : <CreditsMobile hide={true} />}
            </div>
        </div>
    );
};
