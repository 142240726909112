import { History } from "history";
import { ComponentType } from "react";
import { ContentType } from "../../../common/types/Data/ContentType";
import { getModuleLoader } from "../../utils/getModuleLoader";
import { ContentNavigatorConfig } from "./ContentNavigatorConfig";
import { ContentProperties } from "./ContentProperties";
import { MinimumPopupProps } from "./MinimumPopupProps";
import { PopupConfig } from "./PopupConfig";
import { ContentNavigator, ContentNavigatorGotoId } from "./withContentNavigator";

export const contentNavigatorHistoryFactory = (): ContentNavigator => {
    let history: History;

    const contentPropertiesStack: Map<string, ContentProperties> = new Map();

    let overridingGotoId: ContentNavigatorGotoId | undefined;

    const gotoId = (id: number | string) => {
        if (overridingGotoId) {
            overridingGotoId(id);
        } else {
            const path = `/content?id=${id}`;
            if (history.location.pathname != path) {
                history.push(path);
            }
        }
    };

    // @todo store the properties in such a way that the route listener can fetch them
    const openWith = <TProps extends MinimumPopupProps>(componentType: ComponentType<TProps>, componentProperties: TProps, config?: PopupConfig) => {
        contentPropertiesStack.set(`${componentProperties.entity.id}`, {
            entity: componentProperties.entity,
            componentProperties,
            componentType: componentType as ComponentType<MinimumPopupProps>,
            popupConfig: config,
        });
        return gotoId(componentProperties.entity.id);
    };

    const open = <TProps extends MinimumPopupProps>(contentType: ContentType, componentProperties: TProps, config?: PopupConfig): void => {
        const moduleLoader = getModuleLoader(contentType);
        if (moduleLoader) {
            return openWith(moduleLoader.popupModule, componentProperties as never, config);
        } else {
            throw new Error(`No module loader is available for "${contentType}".`);
        }
    };

    const close = (pushHistory = true) => {
        if (pushHistory && history) {
            history.push(`/`);
        }
    };

    const configure = (config: ContentNavigatorConfig) => {
        history = config.history;
    };

    const pluckLastContentProperties = (id: number | string) => {
        return contentPropertiesStack.get(`${id}`);
    };

    const overrideGotoId = (newGotoId: ContentNavigatorGotoId) => {
        overridingGotoId = newGotoId;
        return () => {
            overridingGotoId = undefined;
        };
    };

    return { gotoId, open, openWith, overrideGotoId, close, configure, pluckLastContentProperties };
};
