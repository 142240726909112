import React, { ComponentType, PropsWithChildren } from "react";
import { CurrentModuleData } from "../../../common/types/State";
import { MinimumPopupProps } from "../../hocs/ContentNavigator/MinimumPopupProps";
import { WithContentNavigator, withContentNavigator } from "../../hocs/ContentNavigator/withContentNavigator";
import { WithState, withState } from "../../hocs/withState";
import { Popup } from "./Popup";

type Props = {};

type SerializedComponentType = {
    display: string;
    compare: null;
    type?: Function;
};

const componentTypeIsSerialized = (componentType: ComponentType<MinimumPopupProps> | SerializedComponentType): componentType is SerializedComponentType => {
    const { compare } = componentType as SerializedComponentType;
    return componentType.hasOwnProperty("compare") && compare === null && !componentType.hasOwnProperty("type");
};

const currentModuleDataIsComplete = (currentModuleData: CurrentModuleData | undefined): boolean => {
    if (!currentModuleData) {
        return false;
    }

    if (!currentModuleData.hasOwnProperty("componentType") || componentTypeIsSerialized(currentModuleData.componentType)) {
        return false;
    }

    return true;
};

const PopupHandlerDecorated = (props: PropsWithChildren<Props & WithState & WithContentNavigator>) => {
    const { state, contentNavigator } = props;
    const { currentModuleData } = state;

    if (!currentModuleData || !currentModuleDataIsComplete(currentModuleData)) {
        return <></>;
    }

    const { componentType, componentProps, popupParameters } = currentModuleData;

    const { header, shortHeader, showRelatedContent } = { header: componentProps.entity.label, shortHeader: componentProps.entity.shortLabel, showRelatedContent: true, ...popupParameters };

    const onClose = () => {
        contentNavigator.close(true);
    };

    return <Popup contentType={componentType} visible={true} popupProps={componentProps} showRelatedContent={showRelatedContent} header={header} shortHeader={shortHeader} onClose={onClose} />;
};

export const PopupHandler = withState(withContentNavigator(PopupHandlerDecorated));
