import { PagePost } from "../../../../common/Facebook/PagePost";
import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { Props, FacebookVideoBaseModule } from "./FacebookVideoBaseModule";
import { FacebookVideoPopupModule } from "./FacebookVideoPopupModule";

export const moduleLoader: ComponentModuleLoader<PagePost, Props> = {
    baseModule: FacebookVideoBaseModule,
    popupModule: FacebookVideoPopupModule,
    payloadExtractor: (entity) => {
        return {
            entity,
        };
    },
    getSocialTags: (entity) => {
        return {
            description: entity.linkedContent.message,
            name: entity.linkedContent.message,
            previewImage: undefined,
        };
    },
};
