import React, { ComponentType, PropsWithChildren } from "react";

export type WithResetGridScroll = {
    resetGridScroll: () => void;
};

const withResetGridScrollFactory = () => {
    let gridTime = 0;
    const updateScrollReset = (time: number) => {
        const grid = document.querySelector(".gridHolder");
        const scrollSpeed = 3; //Defines the speed of the lerp.
        const minSpeed = 650; //Minimum scroll speed in pixels per second.

        if (gridTime === 0) {
            gridTime = time;
        }

        const deltaTime = (time - gridTime) / 1000;
        gridTime = time;

        if (grid) {
            //Calculated reducing the scroll by X% of its current value per second.
            const lerpSpeed = grid.scrollTop * scrollSpeed * deltaTime;

            //Check if it's higher than the minimum speed per second.
            const altSpeed = minSpeed * deltaTime;
            const speed = Math.max(...[lerpSpeed, altSpeed]);

            //Actually reduce the scroll.
            grid.scrollTop -= speed;

            //Snap and stop if done.
            if (grid.scrollTop <= 1) {
                grid.scrollTop = 0;
            } else {
                requestAnimationFrame(updateScrollReset);
            }
        }
    };

    const resetGridScroll = () => {
        gridTime = 0;
        requestAnimationFrame(updateScrollReset);
    };

    return <TProps,>(Component: ComponentType<TProps & WithResetGridScroll>): ComponentType<TProps> => {
        const ComponentWithGridScroll = (props: PropsWithChildren<TProps>) => {
            return <Component {...props} resetGridScroll={resetGridScroll} />;
        };

        return ComponentWithGridScroll;
    };
};

export const withResetGridScroll = withResetGridScrollFactory();
