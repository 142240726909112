import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { PopulatedLocationProfile } from "../../../../common/types/Data/PopulatedLocationProfile";
import { addThumbnailURLToList } from "../../../utils/addThumbnailURLToList";
import { generateGenericSocialTags } from "../../../utils/generateGenericSocialTags";
import { Props, StaticLocationProfileBaseModule } from "./StaticLocationProfileBaseModule";
import { StaticLocationProfilePopupModule } from "./StaticLocationProfilePopupModuleDecorated";

export const moduleLoader: ComponentModuleLoader<PopulatedLocationProfile, Props> = {
    baseModule: StaticLocationProfileBaseModule,
    popupModule: StaticLocationProfilePopupModule,
    payloadExtractor: (entity) => {
        return {
            entity,
        };
    },
    getAssetURIs: (props: Props): string[] => {
        let output: string[] = [];
        const { entity } = props;

        output = addThumbnailURLToList(entity.linkedContent, output);

        return output;
    },
    getSocialTags: generateGenericSocialTags,
};
