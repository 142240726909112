import { ComponentModuleLoader } from "../../../../common/types/Data/ComponentModuleLoader";
import { PopulatedVideo } from "../../../../common/types/Data/PopulatedVideo";
import { generateGenericSocialTags } from "../../../utils/generateGenericSocialTags";
import { Props, StaticVideoBaseModule } from "./StaticVideoBaseModule";
import { StaticVideoPopupModule } from "./StaticVideoPopupModule";

export const moduleLoader: ComponentModuleLoader<PopulatedVideo, Props> = {
    baseModule: StaticVideoBaseModule,
    popupModule: StaticVideoPopupModule,
    payloadExtractor: (entity) => {
        return {
            flexSize: 1,
            url: entity.linkedContent.url,
            entity,
        };
    },
    getSocialTags: generateGenericSocialTags,
};
