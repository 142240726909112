import React, { PropsWithChildren } from "react";
import { LoadedTagCollection } from "../../../common/types/Data/TagCollection";

type Props = {
    id?: string;
    isActive: boolean;
    activeTagCollection: LoadedTagCollection | undefined;
};

export const Banner = (props: PropsWithChildren<Props>) => {
    const { id, isActive, activeTagCollection, children } = props;
    const key = [id, activeTagCollection ? activeTagCollection.code : ""].join(".");
    const classNames = ["banner"];
    if (isActive) {
        classNames.push("activeBanner");
    }
    return (
        <div className={classNames.join(" ")} key={key}>
            <div className={`bannerImage`} id={id}>
                {children}
            </div>
        </div>
    );
};
