import React, { Component } from "react";

export class BackgroundElements extends Component {
    public render() {
        return (
            <>
                <div className="trees" />
                <div className="cloudsHolder">
                    <div className="clouds" id="cloud2" />
                    <div className="clouds" id="cloud4" />
                    <div className="clouds" id="cloud6" />
                    <div className="clouds" id="birds" />
                    <div className="clouds" id="cloud1" />
                    <div className="clouds" id="cloud3" />
                    <div className="clouds" id="cloud5" />
                </div>
            </>
        );
    }
}
