import React, { PropsWithChildren } from "react";
import { BaseComponentProps } from "../../../../common/types/Data/BaseComponentProps";
import { YoutubeSearchResult } from "../../../../common/Youtube/YoutubeSearchResult";
import { withActions, WithActions } from "../../../hocs/withActions";

export type Props = BaseComponentProps<YoutubeSearchResult> & {};

const YoutubeVideoPopupModuleDecorated = (props: PropsWithChildren<Props & WithActions>) => {
    const { entity } = props;

    return (
        <div className={"youtube-video"}>
            <iframe src={`https://www.youtube.com/embed/${entity.linkedContent.id}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
    );
};

export const YoutubeVideoPopupModule = withActions(YoutubeVideoPopupModuleDecorated);
